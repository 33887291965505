//*******************************************************************************
// Related Tiles Component
//*******************************************************************************


.newsletterform {
	@include breakpoint(mid) {
		display: flex;
	}

	.newsletterform__content {
		padding-right: $spacer * 5;
	}

	form {
		margin-left: auto;
		margin-right: auto;

		@include breakpoint(mid) {
			max-width: 90vw;
		}

		@include breakpoint(max) {
			max-width: 80vw;
		}
	}

	fieldset {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.form__group {
		@include breakpoint(mid) {
			margin-bottom: 0;
			width: 35vw;
			margin-bottom: 1.25vw;
		}
	}

	.form__checkbox {
		min-height: 28px;
	}

	.form__submit {
		@include breakpoint(mid) {
			margin-bottom: 0;
		}
	}

	.form__label {
		display: block;
		flex: 1 0 15vw;
		margin-right: 10px;
		margin-bottom: 1.25vw;

		@include breakpoint(md) {
			flex: 1 0 7vw;
		}
	}

	.form__control {
		@include breakpoint(mid) {
			max-width: none;
		}
	}
}



