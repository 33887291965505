//*******************************************************************************
// Two Column element with sticky text column
//*******************************************************************************

.team {
	$self: &;
	@include breakpoint(mid) {
		display: flex;
	}

	&--sticky {
		#{$self} {
			&__content {
				@include breakpoint(mid) {
					position: sticky;
					top: 5vw;
				}
			}
		}
	}

	//left optional sticky column
	&__content {
		@include breakpoint(mid) {
			flex: 0 0 30vw;
			align-self: flex-start;
		}
	}

	//right column with persons and partners
	&__members {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: baseline;
		align-content: flex-start;
		@include breakpoint(mid) {
			flex: 0 0 45vw;
			margin-left: 5vw;
		}
		@include breakpoint(lg) {
			justify-content: flex-start;
		}

		// element widths
		.partner--organization {
			flex: 0 0 100%;
		}
		.partner--person {
			flex: 0 0 100%;
			@include breakpoint(mid) {
				flex: 0 0 20vw;
			}
			@include breakpoint(lg) {
				margin-right: 2.5vw;
			}
		}
		.partner--person.partner--vertical {
			flex: 0 0 50%;
			@include breakpoint(mid) {
				flex: 0 0 20vw;
			}
			@include breakpoint(lg) {
				flex: 0 0 30%;
				margin-right: 3.33333%;
			}
		}

		// element spacing
		.partner--person + .partner--organization {
			margin-top: 6.25vw;
		}
		.partner--organization + .partner--organization {
			margin-top: 10vw;
		}
		.partner--organization + .partner--person{
			margin-top: 3.75vw;
		}
		.partner--organization + .team__members-headline{
			margin-top: 3.75vw;
		}

		.partner--organization {
			.partner__caption {
				width: 100%;
			}

			.partner__name,
			.partner__description {
				.msie & {
					display: block;
					width: 100%;
				}
			}
		}

	}

	&__members-headline {
		flex: 0 0 100%;
	}

	&__title {
		@include headline2;
	}
	&__lead p{
		@include textbiggersize;
	}
	&__quote {
		@include textbiggersize;

		a {
			@include font-family(light);
		}
	}
}
