//*******************************************************************************
// IMAGE
//*******************************************************************************


.image {
	display: block;
	position: relative;
}


.image__wrapper {
	position: relative;
}


.image__item {
	max-width: none;
	width: 100%;
	border-radius: $border-radius;
}



//
// Image Description
//
.image__desc {
	color: color(black);
	margin-top: 2vw;
	margin-bottom: 0;
	font-size: 2.5vw;
	line-height: 3vw;

	@include breakpoint(min) {
		font-size: 10px;
		line-height: 12px;
	}

	@include breakpoint(mid) {
		font-size: calc(10px + (14 - 10) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(12px + (20 - 12) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-top: 1vw;
		margin-bottom: 1vw;
	}

	@include breakpoint(max) {
		font-size: .875vw;
		line-height: 1.25vw;
	}

	.section--has-image.section--has-backdrop &,
	.section--has-imageportrait.section--has-backdrop & {
		color: color(white);
	}
}



//
// Image Meta Data like Copyright
//
.image__meta {
	position: absolute;
	right: 0;
	bottom: 0;
	display: innline-block;
	color: color(white);
	background-color: rgba(0, 0, 0, .5);
	font-size: 10px;
	line-height: 10px;
	padding: 5px 10px;
	margin: 0;
	border-top-left-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
}
