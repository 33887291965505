//*******************************************************************************
// SPACER
//
// REGEX
//
// Regular Expression to find Padding-Spacer Classes: [\s|"][p][0-5]|[p][t|r|b|l|x|y][0-5][\s|"]
// Regular Expression to find Margin-Spacer  Classes: [\s|"][m][0-5]|[m][t|r|b|l|x|y][0-5][\s|"]
//
//*******************************************************************************



.p-0  { padding: 0 !important; }
.p-1  { padding: $spacer * 1 !important; }
.p-2  { padding: $spacer * 2 !important; }
.p-3  { padding: $spacer * 3 !important; }
.p-4  { padding: $spacer * 4 !important; }
.p-5  { padding: $spacer * 5 !important; }

.pt-0 { padding-top: 0 !important; }
.pt-1 { padding-top: $spacer * 1 !important; }
.pt-2 { padding-top: $spacer * 2 !important; }
.pt-3 { padding-top: $spacer * 3 !important; }
.pt-4 { padding-top: $spacer * 4 !important; }
.pt-5 { padding-top: $spacer * 5 !important; }

.pr-0 { padding-right: 0 !important; }
.pr-1 { padding-right: $spacer * 1 !important; }
.pr-2 { padding-right: $spacer * 2 !important; }
.pr-3 { padding-right: $spacer * 3 !important; }
.pr-4 { padding-right: $spacer * 4 !important; }
.pr-5 { padding-right: $spacer * 5 !important; }

.pb-0 { padding-bottom: 0 !important; }
.pb-1 { padding-bottom: $spacer * 1 !important; }
.pb-2 { padding-bottom: $spacer * 2 !important; }
.pb-3 { padding-bottom: $spacer * 3 !important; }
.pb-4 { padding-bottom: $spacer * 4 !important; }
.pb-5 { padding-bottom: $spacer * 5 !important; }

.pl-0 { padding-left: 0 !important; }
.pl-1 { padding-left: $spacer * 1 !important; }
.pl-2 { padding-left: $spacer * 2 !important; }
.pl-3 { padding-left: $spacer * 3 !important; }
.pl-4 { padding-left: $spacer * 4 !important; }
.pl-5 { padding-left: $spacer * 5 !important; }

.px-0 { padding-left: 0 !important; padding-right: 0 !important; }
.px-1 { padding-left: $spacer * 1 !important; padding-right: $spacer * 1 !important; }
.px-2 { padding-left: $spacer * 2 !important; padding-right: $spacer * 2 !important; }
.px-3 { padding-left: $spacer * 3 !important; padding-right: $spacer * 3 !important; }
.px-4 { padding-left: $spacer * 4 !important; padding-right: $spacer * 4 !important; }
.px-5 { padding-left: $spacer * 5 !important; padding-right: $spacer * 5 !important; }

.py-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
.py-1 { padding-top: $spacer * 1 !important; padding-bottom: $spacer * 1 !important; }
.py-2 { padding-top: $spacer * 2 !important; padding-bottom: $spacer * 2 !important; }
.py-3 { padding-top: $spacer * 3 !important; padding-bottom: $spacer * 3 !important; }
.py-4 { padding-top: $spacer * 4 !important; padding-bottom: $spacer * 4 !important; }
.py-5 { padding-top: $spacer * 5 !important; padding-bottom: $spacer * 5 !important; }


.m-0  { margin: 0 !important; }
.m-1  { margin: $spacer * 1 !important; }
.m-2  { margin: $spacer * 2 !important; }
.m-3  { margin: $spacer * 3 !important; }
.m-4  { margin: $spacer * 4 !important; }
.m-5  { margin: $spacer * 5 !important; }

.mt-0 { margin-top: 0 !important; }
.mt-1 { margin-top: $spacer * 1 !important; }
.mt-2 { margin-top: $spacer * 2 !important; }
.mt-3 { margin-top: $spacer * 3 !important; }
.mt-4 { margin-top: $spacer * 4 !important; }
.mt-5 { margin-top: $spacer * 5 !important; }

.mr-auto { margin-right: auto !important; }
.mr-0 { margin-right: 0 !important; }
.mr-1 { margin-right: $spacer * 1 !important; }
.mr-2 { margin-right: $spacer * 2 !important; }
.mr-3 { margin-right: $spacer * 3 !important; }
.mr-4 { margin-right: $spacer * 4 !important; }
.mr-5 { margin-right: $spacer * 5 !important; }

.mb-0 { margin-bottom: 0 !important; }
.mb-1 { margin-bottom: $spacer * 1 !important; }
.mb-2 { margin-bottom: $spacer * 2 !important; }
.mb-3 { margin-bottom: $spacer * 3 !important; }
.mb-4 { margin-bottom: $spacer * 4 !important; }
.mb-5 { margin-bottom: $spacer * 5 !important; }

.ml-auto { margin-left: auto !important; }
.ml-0 { margin-left: 0 !important; }
.ml-1 { margin-left: $spacer * 1 !important; }
.ml-2 { margin-left: $spacer * 2 !important; }
.ml-3 { margin-left: $spacer * 3 !important; }
.ml-4 { margin-left: $spacer * 4 !important; }
.ml-5 { margin-left: $spacer * 5 !important; }

.mx-auto { margin-left: auto !important; margin-right: auto !important; }
.mx-0 { margin-left: 0 !important; margin-right: 0 !important; }
.mx-1 { margin-left: $spacer * 1 !important; margin-right: $spacer * 1 !important; }
.mx-2 { margin-left: $spacer * 2 !important; margin-right: $spacer * 2 !important; }
.mx-3 { margin-left: $spacer * 3 !important; margin-right: $spacer * 3 !important; }
.mx-4 { margin-left: $spacer * 4 !important; margin-right: $spacer * 4 !important; }
.mx-5 { margin-left: $spacer * 5 !important; margin-right: $spacer * 5 !important; }

.my-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
.my-1 { margin-top: $spacer * 1 !important; margin-bottom: $spacer * 1 !important; }
.my-2 { margin-top: $spacer * 2 !important; margin-bottom: $spacer * 2 !important; }
.my-3 { margin-top: $spacer * 3 !important; margin-bottom: $spacer * 3 !important; }
.my-4 { margin-top: $spacer * 4 !important; margin-bottom: $spacer * 4 !important; }
.my-5 { margin-top: $spacer * 5 !important; margin-bottom: $spacer * 5 !important; }

