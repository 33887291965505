//*******************************************************************************
// List with environmental measures
//*******************************************************************************

.list-grid {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0;
	margin: 0 calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));

	@include breakpoint(mid) {
		margin-left: 0;
		margin-right: 0;
	}

	&__item {
		flex: 0 0 100%;
		margin-bottom: 5vw;
		padding-left: 0;
		line-height: 1.6;

		@include breakpoint(mid) {
			padding-right: 5vw;
			flex: 0 0 50%;

			.msie & {
				flex: 0 1 auto;
				width: 50%;
			}
		}

		// font-size
		font-size: 3.5vw;
		@include breakpoint(min) {
			font-size: $textfontsizemin + px;
		}

		@include breakpoint(mid) {
			font-size: calc(#{$textfontsizemin}px + (20 - #{$textfontsizemin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		}

		@include breakpoint(max) {
			font-size: 1.25vw;
		}
	}

	&__title {
		font-size: inherit;
		line-height: inherit;
		display: flex;
		align-items: baseline;

		.msie & {
			align-items: center;
		}

		svg {
			flex: 0 0 10vw;
			max-width: 55px;
			height: auto;
			margin-right: 6px;
			transform: translateY(20%);

			.msie & {
				transform: none;
				height: 30px;
			}

			@include breakpoint(sl) {
				transform: translateY(28%);
			}
			@include breakpoint(mid) {
				max-width: 65px;
			}
			@include breakpoint(max) {
				max-width: 75px;
				transform: translateY(20%);
			}
		}
	}

	&__content {
		& > *:last-child {
			margin-bottom: 0;
		}
	}
}
