//*******************************************************************************
// Alert - small box with short messages and link to more
//*******************************************************************************

.alert {
	$self: &;
	border-radius: $border-radius;
	box-shadow: $box-shadow--1;
	background: color(white);
	padding: 5vw 5vw;

	@include breakpoint(sm) {
		padding: 1.875vw 2.5vw;
		display: flex;
		flex-direction: row;
	}

	;

	&__title {
		display: block;
		@include font-family(bold);

		@include breakpoint(sm) {
			margin-right: 1.875vw;
		}
	}

	&__message {
		display: block;

		#{$self}__title+& {
			margin-top: 10px;

			@include breakpoint(sm) {
				margin-top: 0;
			}
		}
	}

	&__link {
		margin-top: 10px;

		@include breakpoint(sm) {
			margin-top: 0;
			margin-left: auto;
		}
	}
}
