//*******************************************************************************
// This file contains very basic styles.
//*******************************************************************************

//
// https://css-tricks.com/almanac/properties/s/scroll-behavior/
// https://twitter.com/zachleat/status/1295737380738146304/
//
html {
	scroll-behavior: smooth;

	@media (prefers-reduced-motion: reduce) {
		scroll-behavior: auto;
	}
}



body {
	@include font-family(regular);
	font-size: 16px;
	color: color(black);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
}

.main {
	min-height: 1px;
}



a[href^="tel"] {
	color: color(black) !important;
}


a {
	@include font-family(bold);
	color: color(aqua);
	text-decoration: none;
	border-bottom: 3px solid transparent;
	transition: $transition;

	&:active,
	&:focus,
	&:hover {
		color: color(magenta);
		text-decoration: none;
		border-bottom-color: color(magenta);
	}

	&:focus {
		outline: none;
		text-decoration: none;
		box-shadow: 0 0 0 2px color(magenta);
	}
}


//
//
//
// External Links
.main {
	a[rel="noopener"],
	a[target="_blank"] {
		padding-right: 1.5em;
		$icon-default: '<svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg"><g fill="#{color(aqua)}" fill-rule="nonzero"><path d="M9 4a1 1 0 01.117 1.993L9 6H3a1 1 0 00-.993.883L2 7v11a1 1 0 00.883.993L3 19h11a1 1 0 00.993-.883L15 18v-6a1 1 0 011.993-.117L17 12v6a3 3 0 01-2.824 2.995L14 21H3a3 3 0 01-2.995-2.824L0 18V7a3 3 0 012.824-2.995L3 4h6z"/><path d="M12.648 0H19.827a1.15 1.15 0 011.167 1.038l.006.155v7.16a1.193 1.193 0 01-2.378.138l-.008-.139-.001-4.334L7.97 14.662a1.154 1.154 0 01-1.728-1.523l.096-.109L16.981 2.386h-4.333c-.612 0-1.117-.46-1.185-1.054l-.008-.139c0-.612.46-1.116 1.054-1.185L12.648 0z"/></g></svg>';
		$icon-active: '<svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg"><g fill="#{color(magenta)}" fill-rule="nonzero"><path d="M9 4a1 1 0 01.117 1.993L9 6H3a1 1 0 00-.993.883L2 7v11a1 1 0 00.883.993L3 19h11a1 1 0 00.993-.883L15 18v-6a1 1 0 011.993-.117L17 12v6a3 3 0 01-2.824 2.995L14 21H3a3 3 0 01-2.995-2.824L0 18V7a3 3 0 012.824-2.995L3 4h6z"/><path d="M12.648 0H19.827a1.15 1.15 0 011.167 1.038l.006.155v7.16a1.193 1.193 0 01-2.378.138l-.008-.139-.001-4.334L7.97 14.662a1.154 1.154 0 01-1.728-1.523l.096-.109L16.981 2.386h-4.333c-.612 0-1.117-.46-1.185-1.054l-.008-.139c0-.612.46-1.116 1.054-1.185L12.648 0z"/></g></svg>';
		background-image: svg-url($icon-default);
		background-size: 1.1em;
		background-repeat: no-repeat;
		background-position: right top;
		&:hover,
		&:focus {
			background-image: svg-url($icon-active);
		}
	}

	.externallinks--withouticons {
		a[rel="noopener"],
		a[target="_blank"] {
			padding-right: 0 !important;
			background-image: none !important;
		}
	}
}




//
// This will hide the focus indicator if the element receives focus via the mouse,
// but it will still show up on keyboard focus.
// Source: https://github.com/WICG/focus-visible
//
.js-focus-visible :focus:not(.focus-visible) {
	outline: none;
	box-shadow: none;
}


.coloredlink {
	color: color(black);
}
