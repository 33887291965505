//*******************************************************************************
// Teaser - Default
//*******************************************************************************

.teaser {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 0 5vw;

	@include breakpoint(mid) {
		flex-direction: row;
		margin: 0 10vw 0 5vw;
	}

	&::after {
		content: '';
		position: absolute;
		top: 5vw;
		left: 0;
		right: 5vw;
		z-index: -1;
		display: block;
		border-radius: $border-radius;
		background-color: color(aqua);
		width: auto;
		height: 100%;
		height: calc(100% - 2.5vw);

		@include breakpoint(mid) {
			top: 2.5vw;
			right: 10vw;
		}
	}
}



.teaser__inner {
	order: 2;
	margin-top: 2.5vw;
	padding: 5vw 10vw 5vw 5vw;

	@include breakpoint(mid) {
		order: 1;
		padding: 5vw;
		width: 45vw;
	}
}



.teaser__image {
	width: calc(100% - 5vw);
	margin-left: 5vw;

	@include breakpoint(mid) {
		order: 2;
		width: 40vw;
		// height: 28vw;
		// margin-bottom: 2.5vw;
		margin-left: 0;
	}

	.image__wrapper {
		// height: 100%;
		// overflow: hidden;
		box-shadow: $box-shadow--2;
		border-radius: $border-radius;
	}

	.image__item {
		min-height: 100%;
		object-fit: cover;
	}

	.image__desc {
		color: color(white);
		margin-right: 10vw;
		margin-bottom: 2vw;
	}
}






.teaser__title,
.teaser__content {
	@include breakpoint(mid) {
		width: 35vw;
	}
}



.teaser__title {
	color: color(white);
	margin-bottom: 8vw;

	@include breakpoint(mid) {
		font-size: calc(36px + (64 - 36) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(40px + (72 - 40) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 2.5vw;
	}

	@include breakpoint(max) {
		font-size: 3vw;
		line-height: 3.5vw;
	}
}



.teaser__content {
	p {
		@include font-family(light);
		color: color(white);
		margin-bottom: 4vw;

		@include breakpoint(min) {
			font-size: 18px;
			line-height: 24px;
		}

		@include breakpoint(mid) {
			font-size: calc(24px + (32 - 24) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
			line-height: calc(32px + (40 - 32) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
			margin-bottom: 2vw;
		}

		@include breakpoint(max) {
			font-size: 1.75vw;
			line-height: 2.625vw;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}


