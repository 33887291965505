//*******************************************************************************
// VIDEOPLAYER
//*******************************************************************************

.video {
	position: relative;
}

.embed-responsive,
.video__inner {
	background-color: color(middlegrey);
	border-radius: $border-radius;
	display: block;
	overflow: hidden;
	padding: 0;
	position: relative;
	width: 100%;
	z-index: 1;

	&::before {
		display: block;
		content: '';
		padding-bottom: 56.25%;
	}

	.embed-responsive-item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
		object-fit: contain;
	}
}

.video__media {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	object-fit: contain;

	&:hover {
		cursor: pointer;
	}

	&.is--hidden {
		opacity: 0;
		visibility: hidden;
	}
}

.video__cover {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	max-width: none;
	width: 100%;
	border: none;
	opacity: .7;
	transition: opacity .3s ease;

	// Special Version for own html5 Videoplayer
	video + & {
		opacity: 1;
	}
}

.video__buttonwrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.1);
	transition: background-color $transition-time $transition-function;

	&:focus,
	&:hover {
		background-color: rgba(0,0,0,.2);
		cursor: pointer;
	}

	&:focus {
		outline: none;
		text-decoration: none;
		box-shadow: inset 0 0 0 3px color(black);
	}


	&:focus,
	&:hover {
		.video__button,
		.teaser__playvideo {

		}
		svg {
			fill: color(magenta);
		}
	}

	svg {
		pointer-events: none;
		fill: color(white);
		transition: $transition;
	}

}


.video__button {
	position: absolute !important;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 80px;
	max-height: 80px;
}

.video__infos {
	@extend %media__infos;
}



.video__desc,
.video__meta {
	@extend %media__desc;
}
