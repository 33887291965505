//*******************************************************************************
// INTRO
//*******************************************************************************


.intro {

	.train {
		z-index: 1;
		margin-top: -17vw;
		@include breakpoint(min) {
			margin-top: -8vw;
		}
	}

	&__top {
		position: relative;
		z-index: 2;
		width: 80vw;
		margin-left: auto;
		margin-right: auto;

		@include breakpoint(mid) {
			display: grid;
			grid-template-columns: 30vw 50vw;
		}
	}

	&__title {
		@include breakpoint(mid) {
			grid-column: 1;
		}
	}

	&__media {
		@include breakpoint(mid) {
			grid-column: 2;
			grid-row: 1 / span 2;
			padding-left: 5vw;
		}

		.video__inner {
			box-shadow: $box-shadow--2;
		}

		//placeholder only
		.video {
			width: 100%;
		}
	}

	&__logos {
		display: flex;
		flex-wrap: wrap;
		margin: 18px auto 0;
		justify-content: center;

		@include breakpoint(mid) {
			justify-content: space-between;
			margin: 0 -10px 0;
			grid-column: 1;
			grid-row: 2;
		}
	}

	&__logo {
		flex: 0 1 auto;
		padding: 0 10px;
		margin-bottom: 20px;

		img {
			display: block;
			width: auto;
			height: 10vw;

			@include breakpoint(sm) {
				height: 54px;
			}
			@include breakpoint(mid) {
				height: 45px;
			}
			@include breakpoint(lg) {
				height: 60px;
			}
		}
	}
}
