//*******************************************************************************
// MENU
//*******************************************************************************

//
// Main Manü
//

.menu {
	$self: &;
	display: none;
	flex-direction: column;
	margin-bottom: 0;
	width: 100%;

	@include breakpoint($breakpoint-mobile-navigation) {
		display: flex;
		width: auto;
		position: static;
		flex-direction: row;
		margin-right: -20px;
	}

	&--open {
		display: flex;
		position: absolute;
		top: 60px;
		left: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		margin: 0;
		padding: 20vw 5vw 60vw;
		overflow: scroll;
		background-color: color(bright);
		z-index: 100;

		@include breakpoint($breakpoint-mobile-navigation) {
			padding: 5vw;
		}
	}

	&__item {
		list-style-type: none;
		margin: 0;
		padding: 0;

		@include breakpoint($breakpoint-mobile-navigation) {
			display: flex;
			align-items: center;
		}

		&--mobile {
			@include breakpoint($breakpoint-mobile-navigation) {
				display: none;
			}
		}
	}

	&__button {
		margin: 10vw 0;

		&.btn {
			background-color: color(magenta);
			border-color: color(magenta);
			padding: 6px 12px;
			height: auto;
			min-height: auto;
			font-size: inherit;
			line-height: inherit;

			@include breakpoint(lg) {
				padding: 6px 36px;
			}

			@include breakpoint(xl) {
				padding: 9px 36px;
			}

			&:hover,
			&:focus {
				background-color: color(aqua);
				border-color: color(aqua);
			}
		}

		@include breakpoint($breakpoint-mobile-navigation) {
			margin: auto 20px auto 1.25vw;
		}
		@include breakpoint(mid) {
			margin: auto 20px;
		}
	}

	&__link {
		padding: 2vw 0;
		position: relative;
		display: block;
		line-height: 1;
		color: color(black);
		@include font-family(bold);
		border-bottom: none !important;

		@media (max-width: ($breakpoint-mobile-navigation - 1px)) {
			min-height: 20vw;
			font-size: 7vw;
			display: flex;
			align-items: center;

			&:focus,
			&:hover,
			&:active {
				border-bottom-color: transparent;
			}
		}

		@include breakpoint($breakpoint-mobile-navigation) {
			padding: 2vw 1.25vw;
		}

		//border
		&::before {
			content: '';
			position: absolute;
			height: 3px;
			right: 1.25vw;
			bottom: 0;
			left: 1.25vw;
			background: color(magenta);
			display: none;
		}

		&:hover,
		&:focus,
		&.is-active,
		#{$self}__item.is-active>& #{$self}__item.is-open>& {
			color: color(magenta);

			&::before {
				@include breakpoint($breakpoint-mobile-navigation) {
					display: block;
				}
			}
		}

		// secondary variant used for toplink items in mobile-menu
		&--secondary {

			@media (max-width: ($breakpoint-mobile-navigation - 1px)) {
				font-size: 4vw;
				min-height: 0;
				padding: 0;
				margin-bottom: 5vw;
			}
		}

		#{$self}__item--has-submenu & {
			display: inline-flex;
			align-items: center;

			&::after {
				content: '';
				display: block;
				margin-left: 8px;
				width: 11px;
				height: 6px;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.99.772l.057.066 4.25 4.893a.801.801 0 001.2 0L10.744.847l.072-.081A.483.483 0 0010.9.494.507.507 0 0010.381 0H1.42A.507.507 0 00.9.494c0 .103.034.2.09.278z' fill='%23007E91' fill-rule='nonzero'/%3E%3C/svg%3E");
			}
		}

		#{$self}__item--has-submenu.is-open &::after {
			background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.91 5.228l-.057-.066L5.603.27a.801.801 0 00-1.2 0L.156 5.153l-.072.081A.483.483 0 000 5.506C0 5.778.231 6 .519 6H9.48A.507.507 0 0010 5.506c0-.103-.034-.2-.09-.278z' fill='%23BF077F' fill-rule='nonzero'/%3E%3C/svg%3E");
		}
	}

	&__lang-menu {
		display: flex;
		list-style-type: none;
		margin: 0;
		padding: 0;

		.menu__item {
			display: flex;
			align-items: center;
			margin-right: 2vw;

			&--globe > img {
				width: 4vw;
			}
		}

		.menu__link {
			margin-bottom: 0;
			padding: 10px 0;
			text-transform: uppercase;
		}
	}
}

//
// Submenu
//
.submenu {
	$self: &;
	display: none;
	background: color(white);
	flex-direction: column;
	padding-bottom: 5vw;

	@include breakpoint($breakpoint-mobile-navigation) {
		border-top: 1px solid color(middlegrey);
		position: absolute;
		flex-direction: column;
		bottom: 0;
		left: 0;
		width: 100%;
		padding-bottom: 0;
		transform: translateY(100%);
	}

	@include breakpoint($breakpoint-md) {
		flex-direction: row;
	}

	// white background and shadow element for bottom shadow
	&::before,
	&::after {
		content: '';
		position: absolute;
		right: 0;
		left: 0;
		display: none;

		@include breakpoint($breakpoint-mobile-navigation) {
			display: block;
		}
	}

	&::before {
		height: 40px;
		bottom: 0;
		box-shadow: $box-shadow--1;

		@include breakpoint($breakpoint-mobile-navigation) {
			display: block;
		}
	}

	&::after {
		top: 0;
		bottom: 0;
		background: color(white);
	}

	.menu__item.is-open & {
		display: flex;
	}

	&__main {
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;

		@include breakpoint($breakpoint-mobile-navigation) {
			padding-bottom: 3.75vw;
			flex-wrap: wrap;
			flex-direction: row;
		}

		@include breakpoint($breakpoint-md) {
			flex: 0 0 65%;
		}
	}

	&__col {
		@include breakpoint($breakpoint-mobile-navigation) {
			margin-left: 5vw;

			&--half {
				flex: 0 0 42.5vw;
			}

			&--full {
				flex: 0 0 90vw;
			}
		}

		@include breakpoint($breakpoint-md) {
			margin-left: 5vw;

			&--half {
				flex: 0 0 25vw;
			}

			&--full {
				flex: 0 0 55vw;
			}
		}
	}

	&__aside {
		position: relative;
		z-index: 1;
		margin-top: 5vw;

		@include breakpoint($breakpoint-mobile-navigation) {
			padding: 0 5vw 3.75vw;
			background: color(lightgrey);
			margin-top: 0;
			flex: 0 0 100%;
		}

		@include breakpoint($breakpoint-md) {
			margin-top: 0;
			flex: 0 0 35%;

			.msie & {
				flex-basis: auto;
				width: 35%;
			}
		}

		#{$self}__link {
			@media (max-width: ($breakpoint-mobile-navigation - 1px)) {
				background: color(lightgrey);
				padding-left: 5vw;
			}
		}

	}

	//wraps different links
	&__category {
		margin-bottom: 5vw;

		@include breakpoint($breakpoint-mobile-navigation) {
			margin-top: 2vw;
			margin-bottom: 0;
		}
	}

	&__category-title {
		@include font-family(regular);
		color: color(darkgrey);
		font-size: 12px;
		letter-spacing: 0.08em;
	}

	//map inside
	.interactive-map {
		@include breakpoint($breakpoint-mobile-navigation) {
			margin-top: 2vw;
		}
	}

	// contains title and options icon and description-text
	&__link {
		padding: 3.75vw 5vw 3.75vw 2.5vw;
		background: transparent;
		font-size: 16px;
		display: flex;
		margin-top: 2.5vw;
		margin-left: -2.5vw;
		margin-right: -2.5vw;
		line-height: 1.4;
		border-radius: $border-radius;
		border-bottom: 3px solid transparent;
		@include font-family(bold);
		color: color(black);

		@media (max-width: ($breakpoint-mobile-navigation - 1px)) {
			background: color(lightgrey);
		}

		@include breakpoint($breakpoint-mobile-navigation) {
			margin-top: 1.25vw;
			margin-left: -12px;
			margin-right: -12px;
			padding: 14px 12px;
			//background: color(transparent);
		}

		&:hover,
		&:focus {
			color: color(black);
			background: color(coolgrey);
			border-bottom: 3px solid transparent;
		}

		&.is-active {
			background: color(coolgrey);
			border-bottom: 3px solid color(magenta);
		}

		&+& {
			margin-top: 10px;
		}
	}

	&__link-icon {
		flex: 0 0 10vw;
		width: 10vw;
		height: 10vw;
		margin-right: 5vw;
		display: block;

		@include breakpoint($breakpoint-mobile-navigation) {
			flex: 0 0 40px;
			width: 40px;
			height: 40px;
			margin-right: 20px;
		}

		@include breakpoint(md) {
			flex: 0 0 60px;
			width: 60px;
			height: 60px;
		}
	}

	// &__link-text {}

	&__link-description {
		display: block;
		@include font-family(regular);
		color: color(darkgrey);
		margin-top: 4px;
		font-size: 13px;
		line-height: 1.38;
	}

	//map component inside menu
	.map {
		margin-top: 30px;

		@media (max-width: ($breakpoint-md - 1px)) {
			display: none;
		}
	}

}

//
// Hamburger Menü Icon
//
.showmenu__toggle {
	border: none;
	background: none;
	cursor: pointer;
	font-family: inherit;
	position: relative;
	display: block;
	margin: 20px 0;
	width: 24px;
	height: 20px;
	z-index: 200;

	@include breakpoint($breakpoint-mobile-navigation) {
		display: none;
	}

	&:hover {
		cursor: pointer;
	}

	.header__navigation--bigger & {
		@include breakpoint(mid) {
			display: block;
		}

		@include breakpoint(lg) {
			display: none;
		}
	}
}

.showmenu__icon {

	&,
	&::before,
	&::after {
		display: block;
		position: absolute;
		width: 24px;
		height: 3px;
		content: '';
		cursor: pointer;
		background-color: color(black);
	}

	top: 0px;
	background: #000;

	&::before {
		top: 8px;
	}

	&::after {
		top: 16px;
		width: 16px;
	}
}

.showmenu__input {
	display: none;

	// &:checked+.menu {}
}
