//*******************************************************************************
// ANIMATIONS
//*******************************************************************************

@media (prefers-reduced-motion: no-preference) {
	.animate {

		.textmedia__media,
		&.section--has-image,
		&.section--has-imageportrait {
			opacity: .01;
			transition: opacity .5s ease-in-out, transform .5s ease-in-out;
			transform: translate3d(0, 20px, 0);
		}

		// Show items per Intersection Observer
		.animate-in,
		&.animate-in {
			opacity: 1 !important;
			transform: translate3d(0, 0, 0) !important;
		}

	}
}
