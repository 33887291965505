//*******************************************************************************
// StickyCam
//*******************************************************************************

.stickyCam {
	--color: #007E91;
	--ft-color: #ffffff;
	--bd-size: 4px;
	--bd-radius: 6px;

    z-index: 10;
	position: sticky;
	bottom: 50px;
	height: 0px;
    width: auto;
    margin: 0 5vw;
	color: var(--ft-color);

	@include breakpoint(mid) {
		margin: 0 calc(5vw + 80 * (100vw - 800px) / 800);
	}

	@include breakpoint(max) {
		margin: 0 10vw;
	}
}

.stickyCam__box {
	z-index: 2;
	position: absolute;
	bottom: 0;
	left: 0;
	display: none;
	border: var(--bd-size) solid var(--color);
	background-color: var(--color);
	border-radius: var(--bd-radius);
	overflow: hidden;

	width: 90vw;
    height: 52vw;

	@include breakpoint(mid) {
		min-width: 280px;
		min-height: 170px;
		width: 13vw;
		height: 8vw;
	}
}

.stickyCam__date {
	position: absolute;
	top: 0.4em;
	right: 0.7em;
	display: flex;
    align-items: center;
    gap: 0.6em;
	font-size: 5vw;
    font-weight: 300;
	line-height: 1em;
	z-index: 1;

	& svg {
		width: 1.6em;
	}

	@include breakpoint(mid) {
		top: 0.4em;
		right: 0.7em;
		font-size: max(0.8vw, 18px);
	}
}

.stickyCam__title {
	position: absolute;
	bottom: 0;
	left: 0.4em;
	font-size: 6vw;
    line-height: 1.2em;
	padding-right: 1em;
	z-index: 1;

	@include breakpoint(mid) {
		font-size: max(1vw, 18px);
	}

	& svg {
		height: 0.9em;
		fill: currentColor;
		display: inline;
		width: auto;
		vertical-align: top;
		margin-top: 0.1em;
		margin-left: 0.3em;
	}
}

.stickyCam__link {
	z-index: 2;
	position: absolute;
	inset: 0;
	border: 0;
}


.stickyCam__toggle {
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 13%;
	height: 11%;
	border-radius: 0 0 var(--bd-radius) 0;
	background-color: var(--color);
	cursor: pointer;

	& svg {
		width: 50%;
		fill: currentColor;
	}
}

.stickyCam__toggleOff {

	& svg {
		margin-top: calc(var(--bd-size, 4px) * -1);
	}
}

.stickyCam__toggleOn {
	--size: 3.1rem;

	bottom: 0;
	top: unset;
	width: 1em;
	height: 1em;
	font-size: var(--size);
	border-radius: var(--size);

	& svg {
		width: 60%;
		fill: currentColor;
	}

	@include breakpoint(mid) {
		--size: 4rem;
	}
}

.stickyCam__iframe {
	display: block;
	border: 0;
    margin: -10% -14%;
    width: 130%;
    height: 130%;
}

.stickyCam__img {
	object-fit: cover;
    width: 100%;
    height: 100%;
}

.stickyCam[data-active] {

	& .stickyCam__box {
		display: inline-block;
	}

	& .stickyCam__toggleOn {
		display: none;
	}
}
