//*******************************************************************************
// THIS FILE CONTAINS ALL TYPOGRAPHY DECLARATIONS
//*******************************************************************************

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
	margin-top: 0;
	margin-bottom: 0;
}



//
// compact Text Mixin
//
@mixin textcompactsize() {
	font-size: 3.5vw;
	line-height: 5vw;

	@include breakpoint(min) {
		font-size: $textcompactfontsizemin + px;
		line-height: $textcompactlineheightmin + px;
	}

	@include breakpoint(mid) {
		font-size: calc(#{$textcompactfontsizemin}px + (#{$textcompactfontsizemax} - #{$textcompactfontsizemin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(#{$textcompactlineheightmin}px + (#{$textcompactlineheightmax} - #{$textcompactlineheightmin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
	}

	@include breakpoint(max) {
		font-size: 1vw;
		line-height: 1.5vw;
	}
}



//
// Bigger Text Mixin
//
@mixin textbiggersize() {
	@include font-family(light);
	font-size: calc(#{$textbiggerfontsizemin}px + (#{$textbiggerfontsizemid} - #{$textbiggerfontsizemin}) * ((100vw - #{$textminbreakpoint}px) / (800 - #{$textminbreakpoint})));
	line-height: calc(#{$textbiggerlineheightmin}px + (#{$textbiggerlineheightmid} - #{$textbiggerlineheightmin}) * ((100vw - #{$textminbreakpoint}px) / (8000 - #{$textminbreakpoint})));

	@include breakpoint(mid) {
		font-size: calc(#{$textbiggerfontsizemid}px + (#{$textbiggerfontsizemax} - #{$textbiggerfontsizemid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(#{$textbiggerlineheightmid}px + (#{$textbiggerlineheightmax} - #{$textbiggerlineheightmid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
	}

	@include breakpoint(max) {
		font-size: 1.75vw;
		line-height: 2.625vw;
	}
}

.biggertext,
.leadtext,
.leadtext p {
	@include textbiggersize;
}



//
// Body-Text Mixin
//
@mixin bodytextsize() {
	font-size: 3.5vw;
	line-height: 6vw;

	@include breakpoint(min) {
		font-size: $textfontsizemin + px;
		line-height: $textlineheightmin + px;
	}

	@include breakpoint(mid) {
		font-size: calc(#{$textfontsizemin}px + (#{$textfontsizemax} - #{$textfontsizemin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(#{$textlineheightmin}px + (#{$textlineheightmax} - #{$textlineheightmin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
	}

	@include breakpoint(max) {
		font-size: 1.125vw;
		line-height: 2vw;
	}
}

body,
p,
li {
	@include bodytextsize;
}

p,
ul {
	margin-bottom: 6vw;

	@include breakpoint(min) {
		margin-bottom: 24px;
	}

	@include breakpoint(mid) {
		margin-bottom: 3vw;
	}

	@include breakpoint(max) {
		margin-bottom: 2vw;
	}
}



//
// H1 Mixin
//
@mixin headline1() {
	font-size: 7vw;
	line-height: 8vw;
	margin-bottom: 6vw;

	@include breakpoint(min) {
		font-size: $h1fontsizemin + px;
		line-height: $h1lineheightmin + px;
		// font-size: calc(#{$h1fontsizemid}px + (#{$h1fontsizemax} - #{$h1fontsizemid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		// line-height: calc(#{$h1lineheightmid}px + (#{$h1lineheightmax} - #{$h1lineheightmid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 24px;
	}

	@include breakpoint(mid) {
		font-size: calc(#{$h1fontsizemid}px + (#{$h1fontsizemax} - #{$h1fontsizemid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(#{$h1lineheightmid}px + (#{$h1lineheightmax} - #{$h1lineheightmid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 2vw;
	}

	@include breakpoint(max) {
		font-size: 3.75vw;
		line-height: 4.5vw;
	}
}



//
// H2 Mixin
//
@mixin headline2() {
	font-size: 5vw;
	line-height: 6vw;
	margin-bottom: 12vw;

	@include breakpoint(min) {
		font-size: $h2fontsizemin + px;
		line-height: $h2lineheightmin + px;
		// font-size: calc(#{$h2fontsizemid}px + (#{$h2fontsizemax} - #{$h2fontsizemid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		// line-height: calc(#{$h2lineheightmid}px + (#{$h2lineheightmax} - #{$h2lineheightmid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 48px;
	}

	@include breakpoint(mid) {
		font-size: calc(#{$h2fontsizemid}px + (#{$h2fontsizemax} - #{$h2fontsizemid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(#{$h2lineheightmid}px + (#{$h2lineheightmax} - #{$h2lineheightmid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 4vw;
	}

	@include breakpoint(max) {
		font-size: 3vw;
		line-height: 3.5vw;
	}
}


.h1,
h1,
.h2,
h2,
.h3,
h3,
h4,
h5,
h6,
strong,
.strong {
	@include font-family(bold);
}



//
// H1 & H2
//
.h1,
h1 {
	@include headline1;
}

.h2,
h2 {
	@include headline2;
}



//
// H3
//
.h3,
h3 {
	@include bodytextsize;
	color: color(black);
	margin-bottom: 6vw;

	@include breakpoint(min) {
		margin-bottom: 24px;
	}

	@include breakpoint(mid) {
		margin-bottom: 3vw;
	}

	@include breakpoint(max) {
		margin-bottom: 2vw;
	}
}


h4,
h5,
h6 {
	margin-bottom: 2vw;
}



//
// Font Helpers
//
.text-regular {
	@include font-family(regular);
}

.text-light {
	@include font-family(light);
}

.text-bold {
	@include font-family(bold);
}



strong,
.strong {
	color: inherit;
}



small,
.small {
	font-size: calc(#{$textsmallfontsizemin}px + (#{$textsmallfontsizemax} - #{$textsmallfontsizemin}) * ((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint})));
	line-height: calc(#{$textsmalllineheightmin}px + (#{$textsmalllineheightmax} - #{$textsmalllineheightmin}) * ((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint})));

	@include breakpoint(max) {
		font-size: #{$textsmallfontsizemax}px;
		line-height: #{$textsmalllineheightmax}px;
	}
}
