//*******************************************************************************
// ACCORDION
// https://designsystem.digital.gov/components/accordion/
// SVG to Base64 Background-Image Converter https://codepen.io/jakob-e/pen/doMoML
//*******************************************************************************

.accordion {
	position: relative;
	margin-bottom: 2vw;
	border-radius: $border-radius;
	background-color: color(lightgrey);
	transition: $transition;

	@include breakpoint(mid) {
		margin-left: -1.5vw;
		margin-right: -1.5vw;
		margin-bottom: 1vw;
	}

	@include breakpoint(max) {
		margin-left: -1vw;
		margin-right: -1vw;
		margin-bottom: 0.5vw;
	}

	&:hover,
	&:focus,
	&.is--open {
		background-color: color(aqua);
		cursor: pointer;
	}
}



.accordion__button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border: none;
	text-align: left;
	padding: 3vw 5vw;
	background-color: transparent;
	transition: $transition;

	@include breakpoint(mid) {
		padding: 1.5vw;
		background-position: right 2vw center;
	}

	@include breakpoint(max) {
		line-height: 1.5vw;
		padding: 0.75vw 1vw;
	}

	&.focus-visible {
		color: color(black);
		border-radius: $border-radius;
	}

	&:focus {
		outline: none;
	}

	&:hover {
		color: color(bright);
		cursor: pointer;
	}
}



.accordion__title {
	@include font-family(regular);
	@include bodytextsize;
	margin-bottom: 0;

	@include breakpoint(mid) {
		margin-bottom: 0;
	}

	.accordion.is--open & {
		@include font-family(bold);
	}
}



.accordion__buttontext {
	// @include font-family(bold);

	.accordion.is--open & {
		@include font-family(bold);
		color: color(bright);
	}

	.no-msie & {
		display: flex;
		align-items: center;
		flex-grow: 1;
		min-height: 15vw;
		min-height: clamp(60px, 15vw, 60px);
	}
}


.accordion__icon {
	flex: 0 0 auto;
	display: block;
	width: 20px;
	height: 20px;
	margin-left: 5vw;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 11c.562 0 1 .406 1 1 0 .25-.094.5-.281.719l-8 8a1.101 1.101 0 01-.719.28c-.25 0-.5-.093-.719-.28l-8-8a1.101 1.101 0 01-.28-.719c0-.25.093-.5.28-.719a1.1 1.1 0 01.72-.28c.25 0 .5.093.718.28L16 18.594l7.281-7.313C23.5 11.094 23.75 11 24 11z" fill="#007E91" fill-rule="nonzero"/></svg>');

	.accordion__button:focus &,
	.accordion__button:hover & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 11c.562 0 1 .406 1 1 0 .25-.094.5-.281.719l-8 8a1.101 1.101 0 01-.719.28c-.25 0-.5-.093-.719-.28l-8-8a1.101 1.101 0 01-.28-.719c0-.25.093-.5.28-.719a1.1 1.1 0 01.72-.28c.25 0 .5.093.718.28L16 18.594l7.281-7.313C23.5 11.094 23.75 11 24 11z" fill="#fff" fill-rule="nonzero"/></svg>');
	}

	.accordion__button[aria-expanded=true] & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 21c.562 0 1-.407 1-1 0-.25-.094-.5-.281-.719l-8-8A1.101 1.101 0 0016 11c-.25 0-.5.094-.719.281l-8 8a1.101 1.101 0 00-.28.719c0 .25.093.5.28.718a1.1 1.1 0 00.72.282c.25 0 .5-.094.718-.282L16 13.406l7.281 7.312c.219.188.469.282.719.282z" fill="#fff" fill-rule="nonzero"/></svg>');
	}

	.accordion__button[aria-expanded=true]:focus &,
	.accordion__button[aria-expanded=true]:hover & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 21c.562 0 1-.407 1-1 0-.25-.094-.5-.281-.719l-8-8A1.101 1.101 0 0016 11c-.25 0-.5.094-.719.281l-8 8a1.101 1.101 0 00-.28.719c0 .25.093.5.28.718a1.1 1.1 0 00.72.282c.25 0 .5-.094.718-.282L16 13.406l7.281 7.312c.219.188.469.282.719.282z" fill="#fff" fill-rule="nonzero"/></svg>');
	}

	@include breakpoint(mid) {
		margin-left: 1vw;
	}

	@include breakpoint(max) {
		width: 32px;
		height: 32px;
		margin-left: 2vw;
	}
}



.accordion__collapse {
	overflow: hidden;
	margin-top: 0;
	padding: 0 5vw 3vw 5vw;
	transition: $transition;

	.accordion:not(.is--open) & {
		display: none;
	}

	@include breakpoint(mid) {
		padding: 0 1.5vw;
	}

	@include breakpoint(max) {
		padding: 0 1vw;
	}

	p {
		@include textcompactsize;
		color: color(bright);
	}

	ul:last-child,
	p:last-child {
		margin-bottom: 0;
	}

	a {
		// @include font-family();
		color: color(white);
		border-bottom-color: white;

		&:active,
		&:focus,
		&:hover {
			color: color(magenta);
			border-bottom-color: color(magenta);
		}

		&[rel="noopener"],
		&[target="_blank"] {
			background-image: svg-url('<svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg"><g fill="#fff" fill-rule="nonzero"><path d="M9 4a1 1 0 01.117 1.993L9 6H3a1 1 0 00-.993.883L2 7v11a1 1 0 00.883.993L3 19h11a1 1 0 00.993-.883L15 18v-6a1 1 0 011.993-.117L17 12v6a3 3 0 01-2.824 2.995L14 21H3a3 3 0 01-2.995-2.824L0 18V7a3 3 0 012.824-2.995L3 4h6z"/><path d="M12.648 0H19.827a1.15 1.15 0 011.167 1.038l.006.155v7.16a1.193 1.193 0 01-2.378.138l-.008-.139-.001-4.334L7.97 14.662a1.154 1.154 0 01-1.728-1.523l.096-.109L16.981 2.386h-4.333c-.612 0-1.117-.46-1.185-1.054l-.008-.139c0-.612.46-1.116 1.054-1.185L12.648 0z"/></g></svg>');
		}
	}

	li {
		@include textcompactsize;
		color: color(white);
	}

	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 3vw;

		@include breakpoint(mid) {
			height: 30px;
		}

		@include breakpoint(max) {
			height: 12px;
		}
	}
}


