//*******************************************************************************
// Download List
//*******************************************************************************


.downloadlist {

	.section--small & {
		margin-left: -5vw;
		margin-right: -5vw;

		@include breakpoint(mid) {
			margin-left: 0;
			margin-right: 0;
		}
	}
}


.downloadlist__title {
	padding-left: 5vw;
	padding-right: 5vw;

	@include breakpoint(mid) {
		padding-left: 0;
		padding-right: 0;
	}
}



.downloadlist__list {
	@include breakpoint(mid) {
		margin-left: -1.5vw;
		margin-right: -1.5vw;
	}

	@include breakpoint(max) {
		margin-left: -1vw;
		margin-right: -1vw;
	}
}



.downloadlist__item {
	position: relative;
	list-style: none;
	margin-bottom: 6px;

	@include breakpoint($min: 800px) {
		padding: 0;
	}

	&--additional-item {
		display: none;
	}
}

.downloadlist__show-additional-entries {
	display: none
}

.downloadlist__show-additional-entries:checked + .downloadlist__list .downloadlist__item--additional-item {
	display: block;
}

.downloadlist__toggle-labels {
	display: block;
	cursor: pointer;
	text-align: center;

	&:hover {
		color: color(magenta);
	}
}

// add button styles 'show more' if checkbox is not checked
.downloadlist__show-additional-entries ~ .downloadlist__toggle-labels {
	.downloadlist__show-more {
		display: inline-block;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			width: 1.75em;
			height: 1.75em;
			left: -0.5em;
			top: 50%;
			transform: translate(-100%, -57.5%);
			background-repeat: no-repeat;
			color: color(magenta);
			background-size: 100%;
			background-position: center;
			@include background-svg(
					'<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M16 6c.56 0 1 .44 1 1v8h8c.56 0 1 .44 1 1s-.44 1-1 1h-8v8c0 .56-.44 1-1 1s-1-.44-1-1v-8H7c-.56 0-1-.44-1-1s.44-1 1-1h8V7c0-.56.44-1 1-1z" fill="#BF077F" fill-rule="evenodd"/></svg>'
			);
		}
	}

	.downloadlist__show-less {
		display: none;
	}
}

// add button styles 'show less' if checkbox is checked
.downloadlist__show-additional-entries:checked ~ .downloadlist__toggle-labels {
	.downloadlist__show-more {
		display: none;
	}

	.downloadlist__show-less {
		display: inline-block;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			width: 1em;
			height: 2em;
			left: -0.75em;
			top: 50%;
			transform: translate(-100%, -55%);
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center;
			@include background-svg(
					'<svg width="20" height="2" xmlns="http://www.w3.org/2000/svg"><path d="M1 2h18c.56 0 1-.5 1-1 0-.56-.44-1-1-1H1C.44 0 0 .5 0 1c0 .56.44 1 1 1z" fill="#BF077F" fill-rule="evenodd"/></svg>'
			);
		}
	}
}



.downloadlist__link {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 8px 5vw;
	font-weight: 400;
	color: color(black);
	background-color: color(lightgrey);
	border-radius: $border-radius;
	border-bottom: none;
	text-decoration: none;
	transition: $transition;
	padding-right: 0 !important;
	background-image: none !important;


	&:focus,
	&:hover {
		color: color(white);
		background-color: color(aqua);
		text-decoration: none;
	}

	&:active {
		color: color(white);
	}

	@include breakpoint($min: 800px) {
		flex-wrap: nowrap;
		padding: 0;
	}
}

.downloadlist__link-name {
	$textfontsizemin:   14;
	$textlineheightmin: 20;
	$textfontsizemax:   18;
	$textlineheightmax: 24;

	@include breakpoint(min) {
		font-size: $textfontsizemin + px;
		line-height: $textlineheightmin + px;
	}

	display: inline-block;
	flex: 1 0 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: 40px;
	margin-bottom: 4px;

	@include breakpoint(mid) {
		font-size: calc(#{$textfontsizemin}px + (#{$textfontsizemax} - #{$textfontsizemin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: 1.5;
		flex: 1 1 auto;
		margin: 1.4vw 1.5vw 1.3vw 1.5vw;
		padding: 0;
	}

	@include breakpoint(max) {
		margin: 1vw;
	}
}


.downloadlist__link-filetype,
.downloadlist__link-filesize,
.downloadlist__link-date,
.downloadlist__link-icon {
	font-size: calc(#{$textsmallfontsizemin}px + (#{$textsmallfontsizemax} - #{$textsmallfontsizemin}) * ((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint})));
	line-height: calc(#{$textsmalllineheightmin}px + (#{$textsmalllineheightmax} - #{$textsmalllineheightmin}) * ((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint})));

	display: inline-block;
	margin-bottom: 0;
	color: inherit;
	flex: 0 0 auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	.downloadlist__link:focus &,
	.downloadlist__link:hover & {
		color: inherit;
	}

	.downloadlist__link:active & {
		color: color(white);
	}

	@include breakpoint(min) {
		margin-bottom: 0;
	}

	@include breakpoint(mid) {
		$textfontsizemin:   14;
		$textlineheightmin: 20;
		$textfontsizemax:   18;
		$textlineheightmax: 24;

		font-size: calc(#{$textfontsizemin}px + (#{$textfontsizemax} - #{$textfontsizemin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: 1;

		margin: 1.4vw 1.5vw 1.3vw 1.5vw;
		color: color(black);
	}

	@include breakpoint(max) {
		margin: 1vw;
	}
}



//
// Filetype
//
.downloadlist__link-filetype {
	.section & {
		flex-basis: 15vw;

		@include breakpoint(mid) {
			flex-basis: 8%;
			max-width: 100px;
		}
	}

	.section--small & {
		flex-basis: 15vw;

		@include breakpoint(mid) {
			flex-basis: 10%;
			max-width: 140px;
		}
	}
}



//
// Filesize
//
.downloadlist__link-filesize {
	.section & {
		flex-basis: 15vw;

		@include breakpoint(mid) {
			flex-basis: 10%;
			max-width: 80px;
		}
	}

	.section--small & {
		flex-basis: 15vw;

		@include breakpoint(mid) {
			flex-basis: 13%;
			max-width: 130px;
		}

		@include breakpoint(max) {
			max-width: 90px;
		}
	}
}



//
// Date
//
.downloadlist__link-date {
	.section & {
		flex-basis: 20vw;

		@include breakpoint(mid) {
			flex-basis: 16.7%;
			max-width: 100px;
		}
	}

	.section--small & {
		flex-basis: 20vw;

		@include breakpoint(mid) {
			flex-basis: 16.7%;
			max-width: 160px;
		}

		@include breakpoint(max) {
			max-width: 110px;
		}
	}
}

.downloadlist__link-icon {
	position: absolute;
	top: 50%;
	right: 5vw;
	display: block;
	width: 20px;
	height: 24px;
	padding: 0;
	background: right top no-repeat transparent;
	background-size: 100%;
	@include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 24"><path d="M0 15.9c0-.506.365-.9.833-.9.47 0 .834.394.834.9v5.4c0 .506.364.9.833.9h15c.469 0 .833-.394.833-.9v-5.4c0-.506.365-.9.834-.9.468 0 .833.394.833.9v5.4c0 1.49-1.12 2.7-2.5 2.7h-15C1.12 24 0 22.79 0 21.3v-5.4zm4.241-4.266A.82.82 0 014 11.05c0-.425.295-.85.857-.85.214 0 .429.08.616.24l3.67 3.665V.85c0-.478.375-.85.857-.85s.857.372.857.85v13.255l3.67-3.666a.948.948 0 01.616-.239c.509 0 .857.372.857.85 0 .212-.08.425-.241.611l-5.143 5.1A.948.948 0 0110 17a.835.835 0 01-.59-.239l-5.169-5.127z" fill="#007E91" fill-rule="nonzero"/></svg>');

	.downloadlist__link:focus &,
	.downloadlist__link:hover &,
	.downloadlist__link:active & {
		@include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 24"><path d="M0 15.9c0-.506.365-.9.833-.9.47 0 .834.394.834.9v5.4c0 .506.364.9.833.9h15c.469 0 .833-.394.833-.9v-5.4c0-.506.365-.9.834-.9.468 0 .833.394.833.9v5.4c0 1.49-1.12 2.7-2.5 2.7h-15C1.12 24 0 22.79 0 21.3v-5.4zm4.241-4.266A.82.82 0 014 11.05c0-.425.295-.85.857-.85.214 0 .429.08.616.24l3.67 3.665V.85c0-.478.375-.85.857-.85s.857.372.857.85v13.255l3.67-3.666a.948.948 0 01.616-.239c.509 0 .857.372.857.85 0 .212-.08.425-.241.611l-5.143 5.1A.948.948 0 0110 17a.835.835 0 01-.59-.239l-5.169-5.127z" fill="#fff" fill-rule="nonzero"/></svg>');
	}

	@include breakpoint($min: 800px) {
		position: relative;
		top: auto;
		right: auto;
		margin: 1.5vw;
		background-position: center center;
	}

	@include breakpoint($min: 1600px) {
		flex-basis: 20px;
		width: 20px;
		height: 24px;
		margin: 0.75vw 1vw 0.75vw 0.75vw;
	}
}



//
// Inside Section Small
//
.section--small {
	.downloadlist__link-filetype,
	.downloadlist__link-filesize,
	.downloadlist__link-date,
	.downloadlist__link-icon {
		margin-left: 0;
	}

}
