//*******************************************************************************
// Related Tiles Component
//*******************************************************************************


.relatedtiles {
	position: relative;
	padding-bottom: 10vw;

	@include breakpoint(mid) {
		display: flex;
		justify-content: center;
		width: 98vw;
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 6vw;
	}

	@include breakpoint(max) {
		width: 90vw;
		max-width: none;
		margin-right: 5vw;
		margin-left: 5vw;
	}
}

.tile {
	display: block;
	margin: 0 10vw 8vw 10vw;
	padding: 4vw;
	color: color(black);
	background-color: color(lightgrey);
	border-radius: $border-radius;
	border-bottom: none;
	transition: all $transition-time $transition-function;

	&:focus {
		color: color(black);
		text-decoration: none;
		border-bottom: none;
	}

	&.focus-visible:focus {
		outline: none;
		color: color(white);
		text-decoration: none;
		border-bottom: none;
		background-color: color(aqua);
	}

	&:hover {
		background-color: color(aqua);
		color: color(white);
		text-decoration: none;
		border-bottom: none;
	}

	@include breakpoint(mid) {
		display: flex;
		flex-direction: column;
		flex: 0 1 30vw;
		margin: 0;
		padding: 2vw;
		max-width: 400px;

		&:nth-child(2) {
			margin-left: clamp(16px, 2vw, 40px);
			margin-right: clamp(16px, 2vw, 40px);

			.msie & {
				margin-left: 2vw;
				margin-right: 2vw;
			}
		}
	}

	@include breakpoint(max) {
		max-width: 25vw;

		&:nth-child(2) {
			margin-left: 2.5vw;
			margin-right: 2.5vw;
		}
	}
}




.tile__title {
	transition: $transition;

	@include breakpoint(mid) {
		margin-bottom: 2vw;

		.msie & {
			min-height: 110px;
		}

		.no-msie & {
			display: flex;
			align-items: center;
			flex: 0 1 2vw;
		}
	}

	.tile:focus & {
		color: color(black);
	}

	.tile:hover & {
		color: color(white);
	}

	.tile:active & {
		color: color(white);
	}

	.tile.focus-visible:focus & {
		color: color(white);
	}
}

.tile__copy {
	@include font-family(regular);
	flex: 1 1 auto;

	p {
		@include textcompactsize();
	}
}



.tile__footer {
	margin-bottom: 0;

	.linkbutton {
		display: inline-block;
		padding-right: 1vw;
		color: color(aqua);

		.tile:focus & {
			color: #0CCEEB;
		}

		.tile:hover & {
			color: #0CCEEB;
		}

		&::after {
			.tile:focus &,
			.tile:hover & {
				@include background-svg('<svg width="28" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M20 1a1 1 0 000 1.42l4.6 4.6H1.06a1 1 0 000 2h23.52L20 13.59A1 1 0 0020 15a1 1 0 001.41 0l6.36-6.36a.88.88 0 000-1.27L21.42 1A1 1 0 0020 1z" fill="#0CCEEB" fill-rule="nonzero"/></svg>');
			}
		}
	}
}

