//*******************************************************************************
// sticky dat navigation for blog
//*******************************************************************************


.date-nav {
	$self: &;
	position: sticky;
	top: 15vh;

	&__list {
		display: flex;
		flex-direction: column;
		list-style: none;
		margin: 0;
		padding: 0;
		&--secondary {
			display: none;
			#{$self}__button--active + & {
				display: flex;
			}
			#{$self}__button--disabled + & {
				display: none;
			}
		}
	}
	&__item {
		margin: 0;
		padding: 0;
		//line-height: 0;
	}
	&__button {
		@include font-family(bold);
		display: inline-block;
		color: color(aqua);
		background: transparent;
		border: solid color(white);
		border-width: 0 0 0 6px;
		transition: $transition;
		padding: 0.687vw 0.875vw;
		cursor: pointer;

		&--active {
			border-color: color(middlegrey);
		}

		&:focus-visible,
		&:hover {
			color: color(magenta);
			border-color: color(magenta);
		}

		&--disabled {
			display: none;
		}



		#{$self}__list--secondary & {
			@include font-family(regular);
			color: color(darkgrey);
			border-color: color(middlegrey);
			padding: 0.15vw 0.875vw;

			&--active {
				color: color(aqua);
				border-color: color(aqua);
			}

			&:hover,
			&:focus-visible {
				color: color(magenta);
				border-color: color(magenta);
			}
		}

	}
}
