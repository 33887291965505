//*******************************************************************************
// FOOTER
//*******************************************************************************

.footer {
	margin-top: 5vw;
	padding-bottom: 20px;

	&__visual {
		overflow: hidden;
	}

	&__partner {
		text-align: center;
		margin-bottom: 20px;

		@include breakpoint(mid) {
			margin-bottom: 80px;
		}

		h3 {
			margin-bottom: 0;
		}
	}

	&__logos {
		margin-left: 5vw;
		margin-right: 5vw;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	&__logo {
		flex-shrink: 0;
		padding: 0 10px;
		margin-top: 24px;

		@include breakpoint(mid) {
			padding: 0 40px;
		}

		img {
			height: 42px;
			width: auto;

			@include breakpoint(mid) {
				height: 75px;
			}
		}
	}

	&__row {
		margin-left: 5vw;
		margin-right: 5vw;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__element {
		margin-top: 40px;

		&--left {
			margin-right: 5vw;
		}

		&--right {}
	}

	&__totop {
		display: flex;
		font-weight: 400;
		color: color(black);
		transform: translateY(10px);
		opacity: 0;
		border-bottom: none;

		&:hover {
			color: color(aqua);
		}

		@media (prefers-reduced-motion: no-preference) {
			transition: all $transition-time $transition-function;
		}

		&.is--visible {
			transform: translateY(0%);
			opacity: 1;
		}

		&.intersectionObserverNotSupported {
			transform: translateY(0%);
			opacity: 1;
		}

		svg {
			margin-left: 12px;
			width: 20px;
			height: 27px;
			fill: #007E91;
		}
	}

	&__navigation {
		padding-left: 0;
		margin-bottom: 0;
		display: flex;
		flex-wrap: wrap;

		.list {}

		.list__item {
			&:not(:last-child) {
				margin-right: 20px;
			}
		}

		.list__link {
			color: color(darkgrey);
			font-size: 13px;
			font-weight: 400;
			padding: 5px 0;
			border-bottom: none;

			&:hover {
				color: color(magenta);
			}
		}

		.iconlink {
			color: color(aqua);
			&:hover,
			&:focus {
				color: color(magenta);
			}
			&.focus-visible:focus {
				box-shadow: none;
			}
			svg {
				display: block;
				width: 32px;
				height: 32px;
				fill: currentColor;
			}
		}

	}
}

// dummy Element to trigger the to-top-link
.pixeltowatch {
	position: absolute;
	width: 1px;
	height: 1px;
	top: 200vh; // position to trigger the to-top-link
	top: m#{i}n(100%, 200vh); // Fix position on short pages. m#{i}n as fix to not use Sass-Function here.
	right: 0;
}
