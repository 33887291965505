// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


//
// Debugging
//
// Swap rows for easy enable / disable
//
$htmlLinting: true;
$htmlLinting: false;
$showPesticide: true;
$showPesticide: false;
$showGridDisplayer: true;
$showGridDisplayer: false;
$media-queries-debugging: true;
$media-queries-debugging: false;



//
// Colors
// Get one Value: .test { color: map-get($colors, orange); }
// or use the function: .test { color: color(black); }
//
$colors: (
	black: #000,
	white: #fff,
	bright: #fff,

	magenta: #BF077F,
	aqua: #007E91,

	lightgrey: #F6F8FB,
	middlegrey: #D7DCE1,
	darkgrey: #706E6E,

	coolgrey: #F0F3F5,

	errorred: #E3000F,
);


//
// Font-Sizes
//
$textminbreakpoint: 400;
$textmidbreakpoint: 800;

$textfontsizemin: 14;
$textlineheightmin: 24;
$textfontsizemax: 18;
$textlineheightmax: 32;

$textfontsizeminNew: 16;
$textlineheightminNew: 26;
$textfontsizemaxNew: 20;
$textlineheightmaxNew: 34;

$textcompactfontsizemin: 14;
$textcompactlineheightmin: 20;
$textcompactfontsizemax: 16;
$textcompactlineheightmax: 24;

$textbiggerfontsizemin: 16;
$textbiggerlineheightmin: 24;
$textbiggerfontsizemid: 19;
$textbiggerlineheightmid: 27;
$textbiggerfontsizemax: 28;
$textbiggerlineheightmax: 42;

$textbiggerfontsizeminNew: 18;
$textbiggerlineheightminNew: 27;
$textbiggerfontsizemidNew: 21;
$textbiggerlineheightmidNew: 32;
$textbiggerfontsizemaxNew: 30;
$textbiggerlineheightmaxNew: 45;

$h1fontsizemin: 28;
$h1lineheightmin: 32;
$h1fontsizemid: 36;
$h1lineheightmid: 40;
$h1fontsizemax: 60;
$h1lineheightmax: 72;

$h2fontsizemin: 28;
$h2lineheightmin: 32;
$h2fontsizemid: 36;
$h2lineheightmid: 40;
$h2fontsizemax: 48;
$h2lineheightmax: 56;


$textsmallfontsizemin: 10;
$textsmalllineheightmin: 12;
$textsmallfontsizemax: 14;
$textsmalllineheightmax: 24;

$specialh3textmediafontsizemax: 20;


$transition-time: .2s;
$transition-function: ease-out;
$transition: all $transition-time $transition-function;

$box-shadow--1: 0 2px 8px 0 rgba(19, 24, 33, 0.12), 0 3px 10px 0 rgba(0, 0, 0, 0.05);
$box-shadow--2: 0 0 3px 0 rgba(19, 24, 33, 0.08), 0 1px 3px 0 rgba(19, 24, 33, 0.05), 0 2px 4px 0 rgba(19, 24, 33, 0.05), 0 3px 8px 0 rgba(0, 0, 0, 0.05), 0 8px 24px 0 rgba(19, 24, 33, 0.25);
$border-radius: 6px;


//
// Breakpoints
//
$smallestBreakpoint: xxs;
$breakpoint-xs: 414px; // iPhone Plus
$breakpoint-sm-new: 600px;
$breakpoint-sm: 640px;
$breakpoint-sl: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1520px;

$breakpoint-min: 400px;
$breakpoint-mid: 800px;
$breakpoint-max: 1600px;

$breakpoint-mobile-navigation: $breakpoint-sl;



//
// Spacer Value
//
$spacer: 1vw;




//
// Autoprefixer
// enable grid translations for IE
// Used as control comment
// https://github.com/postcss/autoprefixer#does-autoprefixer-polyfill-grid-layout-for-ie
// https://css-tricks.com/css-grid-in-ie-duplicate-area-names-now-supported/#article-header-id-11
//

/* Globally enable grid prefixes */
/* autoprefixer grid: on */
