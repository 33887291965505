//*******************************************************************************
// Title Component
//*******************************************************************************


.title {
	// outline: 1px solid black;
	@include headline2;
	margin-bottom: 2.5vw;

	@include breakpoint(min) {
		margin-bottom: 2.5vw;
	}
	@include breakpoint(mid) {
		margin-bottom: 2.5vw;
	}
}
