//*******************************************************************************
// Carousel Component
//*******************************************************************************



.carousel {
	@include breakpoint(mid) {
		display: grid;
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;
		grid-template-columns: 9.5fr 2.5vw 8fr;
		grid-template-rows: auto;
	}

	@include breakpoint(max) {
		grid-template-columns: 9fr 2.5vw 6.5fr;
		max-width: none;
	}
}


.carousel__gridmedia {
	@include breakpoint(mid) {
		grid-column: 1 / span 1;
		grid-row: 1;
		position: relative;
	}
}

.carousel__gridcontent {
	@include breakpoint(mid) {
		grid-column: 3 / span 1;
		grid-row: 1;
		display: flex;

		.msie & {
			display: block;
		}
	}
}


// Media Element visible on Mobile
.carousel__listitem .carousel__media {
	@include breakpoint(mid) {
		display: none;
	}

	.image__desc {
		padding-left: 0;
	}
}

// Media Element in extra Contrainer; visible > 800px
.carousel__gridmedia .carousel__media {
	display: none;

	@include breakpoint(mid) {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		visibility: hidden;
		opacity: 0;
		transition: $transition;
		width: 100%;

		// .image__item {
		// 	max-height: 100vh;
		// 	object-fit: contain;
		// }
	}

	&:first-child {
		@include breakpoint(mid) {
		position: relative;
		}
	}
}




.carousel__list {
	position: relative;
	margin: 0;
	padding: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
}



.carousel__listitem {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	border-bottom: 1px solid color(middlegrey);
	$transition: all 1s $transition-function;


	&:last-child {
		border-bottom-color: transparent;
	}
}



.carousel__title {
	@include bodytextsize;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0;
	padding: 5vw 0;

	@include breakpoint(mid) {
		padding: 1.5vw 2vw 1.5vw 2.5vw;
	}

	@include breakpoint(max) {
		padding: 1vw 0;
	}

	&:hover {
		cursor: pointer;
		color: color(black);
	}

	&:focus {
		color: color(black);
		outline: none;
	}
}

// .carousel__titletext {}

.carousel__icon {
	flex: 0 0 auto;
	display: block;
	width: 20px;
	height: 20px;
	margin-right: 3vw;
	margin-left: 2vw;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 11c.562 0 1 .406 1 1 0 .25-.094.5-.281.719l-8 8a1.101 1.101 0 01-.719.28c-.25 0-.5-.093-.719-.28l-8-8a1.101 1.101 0 01-.28-.719c0-.25.093-.5.28-.719a1.1 1.1 0 01.72-.28c.25 0 .5.093.718.28L16 18.594l7.281-7.313C23.5 11.094 23.75 11 24 11z" fill="#000" fill-rule="nonzero"/></svg>');

	.carousel__title:focus &,
	.carousel__title:hover & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 11c.562 0 1 .406 1 1 0 .25-.094.5-.281.719l-8 8a1.101 1.101 0 01-.719.28c-.25 0-.5-.093-.719-.28l-8-8a1.101 1.101 0 01-.28-.719c0-.25.093-.5.28-.719a1.1 1.1 0 01.72-.28c.25 0 .5.093.718.28L16 18.594l7.281-7.313C23.5 11.094 23.75 11 24 11z" fill="#000" fill-rule="nonzero"/></svg>');
	}

	.carousel__title.is--open & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 21c.562 0 1-.407 1-1 0-.25-.094-.5-.281-.719l-8-8A1.101 1.101 0 0016 11c-.25 0-.5.094-.719.281l-8 8a1.101 1.101 0 00-.28.719c0 .25.093.5.28.718a1.1 1.1 0 00.72.282c.25 0 .5-.094.718-.282L16 13.406l7.281 7.312c.219.188.469.282.719.282z" fill="#000" fill-rule="nonzero"/></svg>');
	}

	.carousel__title.is--open:focus &,
	.carousel__title.is--open:hover & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 21c.562 0 1-.407 1-1 0-.25-.094-.5-.281-.719l-8-8A1.101 1.101 0 0016 11c-.25 0-.5.094-.719.281l-8 8a1.101 1.101 0 00-.28.719c0 .25.093.5.28.718a1.1 1.1 0 00.72.282c.25 0 .5-.094.718-.282L16 13.406l7.281 7.312c.219.188.469.282.719.282z" fill="#000" fill-rule="nonzero"/></svg>');
	}

	@include breakpoint(mid) {
		margin-right: 0;
		margin-left: .5vw;
	}

	@include breakpoint(max) {
		width: 32px;
		height: 32px;
		margin-right: 1vw;
		margin-left: 2vw;
	}
}

.carousel__collapse {
	overflow: hidden;
	transition: $transition;
}

.carousel__content {
	padding: 6vw 0 3vw 0;

	@include breakpoint(mid) {
		padding: 1.5vw 1.5vw 1.5vw 2.5vw;
	}

	@include breakpoint(max) {
		padding: 1.5vw 0;
	}

	p {
		@include textcompactsize;
	}
}



//
// Reversed Version
//
.carousel--reversed {
	@include breakpoint(mid) {
		grid-template-columns: 8fr 2.5vw 9.5fr;
	}

	@include breakpoint(max) {
		grid-template-columns: 6.5fr 2.5vw 9fr;
	}

	.carousel__gridmedia {
		@include breakpoint(mid) {
			grid-column: 3 / span 1;
			grid-row: 1;
		}
	}

	.carousel__gridcontent {
		@include breakpoint(mid) {
			grid-column: 1 / span 1;
			grid-row: 1;
		}
	}

	.carousel__content {
		@include breakpoint(mid) {
			padding: 2.5vw 1.5vw 1.5vw 1.5vw;
		}

		@include breakpoint(max) {
			padding: 1.5vw 0;
		}
	}
}
