%media__infos {
	margin-top: 2vw;
	margin-bottom: 0;
	padding: 0 5vw;

	@include breakpoint(mid) {
		margin-top: 1vw;
		margin-bottom: 1vw;
	}
}

%media__desc {
	color: color(black);
	font-size: 2vw;
	line-height: 3vw;
	margin-bottom: 0;

	@include breakpoint(mid) {
		font-size: 1vw;
		line-height: 1.5vw;
	}

	@include breakpoint(max) {
		font-size: .75vw;
		line-height: 1.5vw;
	}
}
