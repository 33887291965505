//*******************************************************************************
// Blogpost - Default
//*******************************************************************************

.blogpost {
	// outline: 1px solid red;
	clear: both;

	@include breakpoint(mid) {
		margin-left: 15vw;
		margin-right: 15vw;
	}


}


//wrapper element around post content
.blogpost__section {
	opacity: 1;
	transition: all 500ms $transition-function; // should not exceed animationTime defined in blog.js

	&--fadeOut {
		opacity: 0;
	}
}


.blogpost__date,
.blogpost__title,
.blogpost__content,
.blogpost__readmore-wrapper,
.blogpost__collapsed-content {
	margin-left: 5vw;
	margin-right: 5vw;

	@include breakpoint(mid) {
		margin-left: 0;
		margin-right: 0;
	}
}



.blogpost__date {
	@include font-family(headlight);

	@include breakpoint(min) {
		font-size: 18px;
		line-height: 36px;
		margin-bottom: 16px;
	}

	@include breakpoint(mid) {
		font-size: calc(20px + (24 - 20) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(40px + (48 - 40) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 2vw;
	}

	@include breakpoint(max) {
		font-size: 1.5vw;
		line-height: 3vw;
		margin-bottom: 1vw;
	}
}



.blogpost__title {
	padding-bottom: 0;
	margin-bottom: 24px;

	@include breakpoint(mid) {
		margin-bottom: 3vw;
	}

	@include breakpoint(max) {
		margin-bottom: 2vw;
	}
}



.blogpost__content {
	padding-bottom: 48px;

	@include breakpoint(mid) {
		padding-bottom: 4vw;
	}

	p:last-child {
		margin-bottom: 0;
	}
}



//
// TOggle to show hidden content
//
.blogpost__readmore-wrapper	{
	margin-bottom: 0;
}

.blogpost__readmore {
	@include font-family(bold);
	color: color(aqua);
	background: transparent;
	text-decoration: none;
	border: none;
	border-bottom: 3px solid transparent;
	transition: $transition;
	cursor: pointer;

	&:active,
	&:focus,
	&:hover {
		color: color(magenta);
		text-decoration: none;
		border-bottom-color: color(magenta);
	}

	&:focus {
		outline: none;
		text-decoration: none;
		box-shadow: 0 0 0 2px color(magenta);
	}

	&--hidden {
		opacity: 0;
		visibility: hidden;
	}
}


//
// Initially hidden content
//
.blogpost__collapsed-content {
	display: none;

	&--open {
		display: block;
	}

	& > *:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	// spacer inside collapse content
	& > * {
		&.spacerbottom--zero   { margin-bottom: 0; }
		&.spacerbottom--small  { margin-bottom: 2.5vw; }
		&.spacerbottom--medium { margin-bottom: 5vw; }
		&.spacerbottom--big    { margin-bottom: 10vw; }


		@include breakpoint(0, $breakpoint-mid - 1) {
			&.spacerbottom--sm-zero   { margin-bottom: 0; }
			&.spacerbottom--sm-small  { margin-bottom: 2.5vw; }
			&.spacerbottom--sm-medium { margin-bottom: 5vw; }
			&.spacerbottom--sm-big    { margin-bottom: 10vw; }
		}
	}

	&.focus-visible {
		outline: none;
	}

	.image {
		margin-bottom: 6vw;

		@include breakpoint(min) {
			margin-bottom: 24px;
		}

		@include breakpoint(mid) {
			margin-bottom: 2vw;
		}
	}

	.image__desc {
		padding: 1vw 0;
		margin-top: 0;
	}

	.slideshow {
		.image__wrapper {
			margin-bottom: 0;
		}
		.image__desc {
			margin-top: 0;
			min-height: auto;
		}
	}


}



//
// Blogpost - Left Alignment
//
.blogpost--alignleft {
	@include breakpoint(mid) {
		width: 47.5vw;
		margin-left: 0;
		margin-right: 0;
	}

	@include breakpoint(max) {
		width: 45vw;
	}
}



//
// Blogpost - Right Alignment
//
.blogpost--alignright {
	@include breakpoint(mid) {
		width: 47.5vw;
		margin-left: calc(100vw - 47.5vw - (2 * ( 5vw + (160 - 80) * ((100vw - 800px) / (1600 - 800))) ));
		margin-right: 0;

		.section &,
		.section--full &,
		.section--small & {
			margin-bottom: 5vw;
		}
		.section.spacerbottom--zero   & { margin-bottom: 0; }
		.section.spacerbottom--small  & { margin-bottom: 2.5vw; }
		.section.spacerbottom--medium & { margin-bottom: 5vw; }
		.section.spacerbottom--big    & { margin-bottom: 10vw; }
	}

	@include breakpoint(max) {
		width: 45vw;
		margin-left: 35vw;
	}
}
