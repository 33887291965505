//*******************************************************************************
// Tooltip
// https://pixelbar.be/blog/css-profi-tip-tooltip-attributen/
//*******************************************************************************

// Only in Text-Content Wrapper!
.textcontent {

	a[href="#"][title]:after {
		content: attr(title);
	}

	.tooltip:after {
		content: attr(data-tooltip);
	}

	span[data-footnote]:after {
		content: attr(data-footnote);
	}

	.tooltip,
	span[data-footnote],
	a[href="#"][title] {
		display: inline-block;
		position: relative;
		line-height: 1.2;
		color: color(black);
		border-bottom: 2px dotted color(black);

		&::before {
			visibility: hidden;
			opacity: 0;
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			border-top: 10px solid color(darkgrey);
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			left: 50%;
			bottom: 130%;
			transition: all 0.1s ease;
			transform: translate(-50%, -100%);
		}

		&::after {
			visibility: hidden;
			opacity: 0;
			position: absolute;
			left: -50%;
			bottom: 130%;
			width: 200%;
			background: color(darkgrey);
			color: color(white);
			font-size: 80%;
			line-height: 1.4;
			padding: 5px 10px;
			border-radius: 3px;
			transition: all 0.2s ease;
			transform: translate(0, -20%);

			@include breakpoint(mid) {
				left: 50%;
				width: 250px;
				transform: translate(-50%, -20%);
			}
		}

		&:focus,
		&:hover {
			cursor: pointer;

			&::before {
				visibility: visible;
				opacity: 1;
				transform: translate(-50%, 70%);
			}

			&::after {
				visibility: visible;
				opacity: 1;
				transform: translate(0, 0);

				@include breakpoint(mid) {
					transform: translate(-50%, 0%);
				}
			}
		}
	}
}
