//*******************************************************************************
// Text-Media Component
//*******************************************************************************

//
// Default: Media left; Text right
//
.textmedia {
	// outline: 1px solid rebeccapurple;

	@include breakpoint(mid) {
		display: grid;
		grid-template-columns: repeat(18, 1fr);
		grid-template-rows: auto auto;
	}

	@include breakpoint(max) {
		grid-template-columns: repeat(16, 1fr);
	}

	&.textmedia--has-imageaddon {
		@include breakpoint(mid) {
			grid-template-rows: 1fr auto;
		}
	}
}



// Media Item
.textmedia__media {
	margin-bottom: 5vw;

	@include breakpoint(mid) {
		grid-row: 1;
		grid-column-start: 1;
		grid-column-end: span 10;
		align-self: end;
		margin-bottom: 0;
		margin-right: 2.5vw;
	}

	@include breakpoint(max) {
		grid-column-end: span 9;
		margin-right: 0;
	}

	.textmedia--has-imageaddon & {
		@include breakpoint(mid) {
			grid-row: span 2;
		}
	}

	.image__desc,
	.video__infos {
		@include breakpoint(mid) {
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
		}
	}
}



// Text Column + Extra Text Cell
.textmedia__text,
.textmedia__textaddon,
.textmedia__imageaddon {
	margin-left: 5vw;
	margin-right: 5vw;

	@include breakpoint(min) {
		margin: 0 calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(mid) {
		grid-row: 1;
		grid-column-start: 11;
		grid-column-end: span 8;
		align-self: end;
		margin-left: 2.5vw;
		margin-right: 0;
	}

	@include breakpoint(max) {
		grid-column-end: span 6;
		margin-left: 0;
	}
}


//
.textmedia__text {
	margin-bottom: 24px;

	@include breakpoint(mid) {
		margin-bottom: -.55em; // Hack to get the text bottom line at the same level as the media item
	}

	h3 {
		@include font-family(regular);
		font-size: 3.5vw;
		line-height: 6vw;

		@include breakpoint(min) {
			font-size: $textfontsizemin + px;
			line-height: $textlineheightmin + px;
		}

		@include breakpoint(mid) {
			font-size: calc(#{$textfontsizemin}px + (#{$specialh3textmediafontsizemax} - #{$textfontsizemin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
			line-height: calc(#{$textlineheightmin}px + (#{$textlineheightmax} - #{$textlineheightmin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		}

		@include breakpoint(max) {
			font-size: 1.25vw;
			line-height: 2vw;
		}
	}

	p:last-child {
		margin-bottom: 0;
	}
}



// Extra Text Cell
.textmedia__textaddon {
	margin-top: 24px;

	@include breakpoint(mid) {
		grid-row: 2;
		margin-top: 3vw;
	}

	@include breakpoint(max) {
		margin-top: 2vw;
	}

	.textmedia--has-imageaddon & {
		@include breakpoint(mid) {
			grid-row: 3;
		}
	}
}

// Extra Image Cell
.textmedia__imageaddon {
	margin-top: 24px;

	@include breakpoint(mid) {
		grid-row: 2;
		margin-top: 3vw;
	}

	@include breakpoint(max) {
		margin-top: 2vw;
	}
}



//
// Text left, Media Item right
//
.textmedia--reversed {
	// outline: 1px solid rebeccapurple;

	.textmedia__media {
		@include breakpoint(mid) {
			grid-column-start: 9;
			margin-right: 0;
			margin-left: 2.5vw;
		}

		@include breakpoint(max) {
			grid-column-start: 8;
			margin-left: 0;
		}
	}

	.textmedia__text,
	.textmedia__textaddon {
		@include breakpoint(mid) {
			grid-column-start: 1;
			margin-left: 0;
			margin-right: 2.5vw;
		}

		@include breakpoint(max) {
			margin-right: 0;
		}
	}
}



//
// Text Media with Video Element
//
.textmedia__media {

	.textmedia__videowrapper {
		position: relative;
		padding-top: 56.15%;
		border-radius: $border-radius;
		background-color: color(bright);
		overflow: hidden;
	}

	.textmedia__media {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		object-fit: contain;

		&:focus {
			outline: none;
		}

		&:hover {
			cursor: pointer;
		}
	}
}
