//*******************************************************************************
// Title Visual
// Image element with possible overlayp to top
//*******************************************************************************


.title-visual {
	margin-left: 25vw;
	margin-right: 0;

	&__image {
		width: 100%;
		height: auto;
		@include breakpoint(0px, $breakpoint-min) {
			margin-top: 0 !important;
		}
	}

}
