//*******************************************************************************
// TRAIN
// section with animated train - different variants for intro-section and footer
//*******************************************************************************


.train {
	$self: &;

	position: relative;
	overflow: hidden;

	&--intro {
		#{$self} {
			&__foreground {
				width: 100%;
			}
			&__train {
				bottom: 26px;
				@media (min-width: 800px) {
					bottom: 3vw;
				}
			}
		}
	}
	&--footer {
		#{$self} {
			&__train {
				bottom: 73px;
				@media (min-width: 800px) {
					bottom: 8.7vw;
				}
			}
		}
	}

	&__background {
		z-index: 1;
		display: block;
		height: auto;
		min-width: 800px;

		img {
			width: 100%;
		}
	}

	&__foreground {
		z-index: 3;
		display: block;
		height: auto;
		position: absolute;
		bottom: 0;
		left: 0;
		min-width: 800px;

		img {
			width: 100%;
		}
	}

	&__train {
		z-index: 2;
		position: absolute;
		left: 100%;
		transform: translateX(0);
		transition: transform 100ms linear;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE11 */
        	transition: none;
       }
		width: 87vw;
		min-width: 700px;

		img {
			display: block;
			width: 100%;
			height: auto;
			max-width: initial;
		}
	}

}
