//*******************************************************************************
// Partner to display team member or partner organization
//*******************************************************************************


.partner {
	$self: &;
	display: flex;
	&--person {
		margin-bottom: 3.75vw;
		#{$self} {
			&__image {
				border-radius: 50%;
				flex: 0 0 15vw;
				width: 15vw;
				height: 15vw;
				margin-right: 1.25vw;
				@include breakpoint(mid) {
					flex: 0 0 7.5vw;
					width: 7.5vw;
					height: 7.5vw;
				}
			}
			&__description {
				line-height: 1.4;
			}
		}
	}
	&--organization {
		flex: 0 0 100%;
		#{$self} {
			&__image {
				flex: 0 0 20vw;
				width: 20vw;
				margin-right: 3.75vw;
				height: auto;
				@include breakpoint(mid) {
					flex: 0 0 11.25vw;
					width: 11.25vw;
				}
			}
		}

	}
	&--vertical {
		flex-direction: column;
		flex: 0 0 33.33%;
		align-items: center;
		#{$self} {
			&__image {
				margin-right: 0;
				align-self: initial;
				margin-bottom: 1.25vw;
			}
			&__caption {
				text-align: center;
				align-items: center;
			}
			&__link {
				align-self: center;
			}
		}
	}
	&__image {
		align-self: flex-start;
	}
	&__caption {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	&__name {
		@include font-family(bold);
	}
	&__description {
		margin-top: 0.625vw;
	}
	&__link {
		margin-top: 0.625vw;
	}
}
