//*******************************************************************************
// Filter
// Sticky filter-list for filtering blogposts
//*******************************************************************************


.filter {
	$self: &;
	position: relative;
	z-index: 10;

	&.filter--expanded {
		.filter__wrapper .filter__list {
			background-color: color(white);
			bottom: auto;
			padding: 15vw 2vw 10vw 2vw;
			.filter__item {
				display: flex;
				padding: 3vw 0;
			}
		}
	}

	.filter__wrapper {
		// position: relative;
		background: transparent;
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		transition: $transition;
		margin-left: 15vw;
	}

	//usesd to toggle filter on mobile
	.filter__toggle {
		display: inline-flex;
		align-items: center;
		position: relative;
		z-index: 2;

		@include breakpoint(mid) {
			display: none;
		}

		svg {
			width: 17px;
			height: 9px;
			margin-left: 8px;
			.filter--expanded & {
				transform: rotate(-180deg);
			}
			path {
				fill: currentColor;
			}
		}
	}

	.filter__toggle-text {
		white-space: nowrap;
	}

	.filter__list {
		position: absolute;
		z-index: 1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: color(white);
		margin: 0;
		list-style: none;
		display: flex;
		flex-direction: column;
		padding: calc(4vw + 32px) 10vw 0;
		@include breakpoint(min) {
			padding-left: calc(20vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
			padding-right: calc(20vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
		}
		@include breakpoint(mid) {
			position: static;
			padding: 0;
			flex-direction: row;
		}
	}

	.filter__item {
		display: none;
		padding: 0 10px;
		align-items: center;
		gap: 10px;
		margin-right: 2vw;

		@include breakpoint(mid) {
			display: flex;
		}

		.filter__checkbox[type="checkbox"] {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			display: inline-block;
			position: relative;

			background-color: white;
			border: 1px solid color(black);

			&:checked {
				border-color: color(aqua);

				&::before {
					content: "";
					width: 14px;
					height: 14px;
					background-color: color(aqua);
					border-radius: 50%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					display: block;
				}
			}
		}
		label {
			margin: 0;
		}
	}

	.filter__button {
		@include font-family(bold);
		color: color(black);
		text-decoration: none;
		transition: $transition;
		display: block;
		border-width: 0 0 3px 0;
		// padding: 1.875vw 0;
		cursor: pointer;
		&:hover,
		&:focus {
			color: color(aqua);
		}

		&--active {
			color: color(aqua);
		}
	}
}
