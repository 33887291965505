//*******************************************************************************
// Interactive route section map
//*******************************************************************************


.interactive-map {
	$self: &;
	$spacing-bottom--mobile: 9vw;
	$spacing-bottom: 5.7vw;

	padding-bottom: $spacing-bottom--mobile;
	overflow: hidden;
	@include breakpoint(mid) {
		padding-bottom: $spacing-bottom;
	}
	@include breakpoint(md) {
		overflow: visible;
	}

	&__wrapper {
		position: relative;
		margin-left: -20vw;
		margin-right: -53vw;
		margin-top: -15vw;
		@include breakpoint(mid) {
			margin-left: 0;
			margin-right: 0;
			margin-top: 0;
		}
	}

	&__link-wrapper {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 16.5%;
		right: 36.6%;
		display: flex;
	}
	&__link {
		position: relative;
		border: none;
		color: color(black);
		&--pfa1 {
			flex: 0 0 13.30%;
			width: 13.30%;
			#{$self}__link-box {
				background-color: color(middlegrey)
			}
		}
		&--pfa2 {
			flex: 0 0 49.35%;
			width: 49.35%;
			#{$self}__link-box {
				background-color: color(lightgrey)
			}
			#{$self}__link-text {
				left: 12vw;
				@include breakpoint(sm) {
					left: 7vw;
				}
				@include breakpoint(mid) {
					left: 2vw;
				}
			}
			.submenu & #{$self}__link-text {
				@include breakpoint(mid) {
					left: 4.5vw;
				}
			}
		}
		&--pfa3 {
			flex: 0 0 37.35%;
			width: 37.35%;
			#{$self}__link-box {
				background-color: color(middlegrey)
			}
		}
		&:hover,
		&:focus {

			#{$self}__link-box {
				background-color: color(aqua);
			}
			#{$self}__link-pfa-title {
				color: color(magenta);
			}
		}
	}
	&__link-box {
		position: absolute;
		left: 0;
		top: 71.6%;
		bottom: 0;
		width: 100%;
		transition: $transition;
	}
	&__link-text {
		position: absolute;
		top: 100%;
		left: 0;
		margin-top: 1.25vw;;
		display: flex;
		flex-direction: column;
		white-space: nowrap;
	}
	&__link-pfa-title {
		transition: $transition;
		color: color(darkgrey);
		@extend .small;
		letter-spacing: 1px;
		text-transform: uppercase;
	}

	&__svg {
		position: relative;
		display: block;
		width: 100%;
		height: auto;
		pointer-events: none;
		circle {
			cursor: pointer;
		}
	}
	&__toggle {
		#{$self}:not(.interactive-map--menu) & {
			@include breakpoint(md) {
				cursor: pointer;
				pointer-events: auto;
				&:hover,
				&:focus {
					circle {
						fill: color(aqua);
					}
				}
			}
		}
	}

	// position via js - visibility via activeclass
	&__tooltip {
		$triangle-height: 20px;
		$triangle-width: 30px;
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: opacity $transition-time $transition-function;
		background: color(white);
		padding: 1.25vw;
		transform: translate3d(-50%, -100%,0);
		margin-top: -$triangle-height;
		box-shadow: $box-shadow--2;

		//triangle
		&::before {
			content: '';
			position: absolute;
			bottom: -$triangle-height;
			left: 50%;
			width: 0;
			height: 0;
			border-style: solid;
			transform: translate(-50%,-2px);
			border-width: $triangle-height calc($triangle-width/2) 0 calc($triangle-width/2);
			border-color: #fff transparent transparent transparent;
		}

		&--active {
			opacity: 1;
			//pointer-events: auto;
		}
	}
	&__tooltip-title {
		display: block;
	}
	&__tooltip-subtitle {
		display: block;
		color: color(darkgrey);
		@extend .small;
		@include font-family(regular);
	}
}
