//*******************************************************************************
// Section with interactive map, background-image and iconlist
//*******************************************************************************


.map-section {

	&__content {
		position: relative;
		z-index: 2;
		padding-top: 2.5vw;

		.linkbox {
			@include breakpoint(lg) {
				max-width: 25vw;
			}
		}

	}

	&__background {
		display: none;
		@include breakpoint(lg) {
			display: block;
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			width: 45vw;
		}
	}

	&__map {
		position: relative;
		z-index: 2;
	}
}
