//*******************************************************************************
//
// This file contains all @font-face declarations, if any.
//
// https://fonts.google.com/specimen/Fira+Sans?preview.text_type=custom#standard-styles
//
// https://google-webfonts-helper.herokuapp.com/fonts
//
//*******************************************************************************


//
// Regular
//
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/fira-sans-v11-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('../fonts/fira-sans-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/fira-sans-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/fira-sans-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
		url('../fonts/fira-sans-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/fira-sans-v11-latin-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
}


//
// Light (300)
//
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/fira-sans-v11-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('../fonts/fira-sans-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/fira-sans-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/fira-sans-v11-latin-300.woff') format('woff'), /* Modern Browsers */
		url('../fonts/fira-sans-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/fira-sans-v11-latin-300.svg#FiraSans') format('svg'); /* Legacy iOS */
}


//
// Bold (700)
//
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/fira-sans-v11-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('../fonts/fira-sans-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/fira-sans-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/fira-sans-v11-latin-700.woff') format('woff'), /* Modern Browsers */
		url('../fonts/fira-sans-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/fira-sans-v11-latin-700.svg#FiraSans') format('svg'); /* Legacy iOS */
}



//
// Font Family Mixin
// usage: @include font-family(bold);
//
 @mixin font-family($value: regular) {
	@if $value == light {
		font-family: "Fira Sans",Arial,sans-serif;
		font-weight: 300;
	}
	@else if $value == bold {
		font-family: "Fira Sans",Arial,sans-serif;
		font-weight: 700;
	}
	@else {
		font-family: "Fira Sans",Arial,sans-serif;
		font-weight: 400;
	}
}
