//*******************************************************************************
// Scrollable map component
//*******************************************************************************

.map {
	$self: &;
	position: relative;

	// scrollable map only on small screens
	&--small {
		margin-left: 10vw;
		margin-right: 0;
		@include breakpoint(mid) {
			margin-right: 10vw;
		}

		#{$self} {

			&__svg {
				@include breakpoint(mid) {
					width: 100%;
					height: auto;
				}
			}

			&__button {
				@include breakpoint(mid) {
					display: none;
				}
			}
		}
	}

	// map on all screen sizes scrollable
	&--large {
		margin-left: 10vw;
		margin-right: 0;

		#{$self} {
			&__image-wrapper {
				padding-bottom: 2.5vw;
			}
		}
	}

	&__image-wrapper {
		overflow-x: auto;

		/*Custom Scrollbar*/
		&::-webkit-scrollbar {
			height: 10px;
		}
		/* Track */
		&::-webkit-scrollbar-track {
			background: color(lightgrey);
		}
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: color(aqua);
			transition: $transition;
			border-radius: 9px;
		}
		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: color(magenta);
		}
	}

	&__svg {
		display: block;
		height: 700px;
		max-height: 80vh;
		width: auto;
		max-width: none;
	}

	&__button {
		padding: 0;
		position: absolute;
		top: 50%;
		top: calc(50% - 2.5vw);
		transform: translateY(-50%);
		display: inline-flex;
		justify-content: center;
		align-items: center;
		outline: none;
		text-decoration: none;
		cursor: pointer;
		background-color: color(aqua);
		border-radius: 50%;
		transition: $transition;
		border: 5px solid rgba(255, 255, 255, 0.5);
		background-clip: padding-box;
		opacity: 0;
		width: 40px;
		height: 40px;

		@include breakpoint(mid) {
			width: 4.375vw;
			height: 4.375vw;
		}

		@include breakpoint(max) {
			width: 70px;
			height: 70px;
		}

		&::before {
			content: '';
			position: absolute;
			top: -10px;
			right: -10px;
			bottom: -10px;
			left: -10px;
			display: block;
		}

		&:focus,
		&:hover,
		&:active {
			outline: none;
			text-decoration: none;
			background-color: color(magenta);
		}

		svg {
			display: block;
			width: 10px;
			height: 15px;
			transition: $transition;
			transform: translateX(0);

			@include breakpoint(mid) {
				width: 1vw;
				height: 1.875vw;
			}

			@include breakpoint(max) {
				width: 17px;
				height: 30px;
			}
		}

		&--left {
			left: 10px;

			.map--scrollable-left & {
				opacity: 1;
			}

			&:hover svg,
			&:focus svg {
				transform: translateX(-3px);
			}

			&:active svg {
				transform: translateX(-5px);
			}
		}

		&--right {
			right: 10px;

			.map--scrollable-right & {
				opacity: 1;
			}

			&:hover svg,
			&:focus svg {
				transform: translateX(3px);
			}

			&:active svg {
				transform: translateX(5px);
			}
		}
	}
}
