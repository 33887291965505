.construction-cam {
    display: block;
    background: #F0F3F5;
    border-radius: 6px;
	position: relative;
    overflow: hidden;

	&--consent {
		padding-bottom: 320px;
	}
}

.construction-cam__image {
    display: block;
    text-align: right;
}

.construction-cam__image img {
    display: inline-block;
    width: 35%;
    max-width: 450px;
    margin-right: 2.5vw;
}

.construction-cam__tabs {
    display: block;
}

.construction-cam__tabs__tab {
    display: block;
}

.construction-cam__tablabel {
    display: inline-block;
    font-weight: bold;
    font-size: .8em;
    padding: 1em .5em .6em .5em;
    margin: 0 .8em;
    position: relative;
    cursor: pointer;
}

.construction-cam__tablabel[data-tab="1"] {
    margin-left: 1.5em;
}

.construction-cam__tablabel::after {
    content: '';
    display: block;
    width: calc(100% + 1em);
    background: #BF077F;
    height: 4px;
    border-radius: 7px;
    margin-left: -.5em;
    opacity: 0;
}

.construction-cam__tab {
    display: none;
}

.construction-cam__tab .video,
.construction-cam__tab img {
    width: 100%;
}

.construction-cam__tab .video .video__inner {
    border-radius: 0;
}

.construction-cam__radio {
    display: none;
}

.construction-cam__tab--overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.5);
	color: #fff;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2em;
	z-index: 1;
}

.construction-cam__radio[data-tab="1"]:checked ~ .construction-cam__tab[data-tab="1"] {
    display: block;
}

.construction-cam__radio[data-tab="1"]:checked ~ .construction-cam__tablabel[data-tab="1"]::after {
    opacity: 1;
}

.construction-cam__radio[data-tab="2"]:checked ~ .construction-cam__tab[data-tab="2"] {
    display: block;
}

.construction-cam__radio[data-tab="2"]:checked ~ .construction-cam__tablabel[data-tab="2"]::after {
    opacity: 1;
}

.construction-cam__radio[data-tab="3"]:checked ~ .construction-cam__tab[data-tab="3"] {
    display: block;
}

.construction-cam__radio[data-tab="3"]:checked ~ .construction-cam__tablabel[data-tab="3"]::after {
    opacity: 1;
}
