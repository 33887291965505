//*******************************************************************************
// Carousel Component
//*******************************************************************************

.slideshow {
	position: relative;
	&__slider-wrapper {

	}

	&__slide {
		& + & {
			display: none; //hide subsequent element until slider is init
		}

		// solution for spacing below image even if no caption
		.image__wrapper {
			margin-bottom: 5vw;
		}
		img {
			background: color(darkgrey);
		}
		.image__desc {
			margin-top: -5vw;
			min-height: 5vw;
		}
	}

	&__prev,
	&__next {
		padding: 0;
		position: absolute;
		top: 50%;
		top: calc(50% - 2.5vw);
		transform: translateY(-50%);
		width: 35px;
		height: 35px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		outline: none;
		text-decoration: none;
		cursor: pointer;
		background-color: color(aqua);
		border-radius: 50%;
		transition: $transition;
		border: 5px solid rgba(255,255,255,0.5);
		background-clip: padding-box;

		@include breakpoint(mid) {
			width: 4.375vw;
			height: 4.375vw;
		}

		@include breakpoint(max) {
			width: 70px;
			height: 70px;
		}

		&:focus,
		&:hover,
		&:active {
			outline: none;
			text-decoration: none;
			background-color: color(magenta);
		}

		&::before {
			content: '';
			position: absolute;
			top: -10px;
			right: -10px;
			bottom: -10px;
			left: -10px;
			display: block;
		}

		svg {
			display: block;
			width: 10px;
			height: 15px;
			transition: $transition;
			transform: translateX(0);

			@include breakpoint(mid) {
				width: 1vw;
				height: 1.875vw;
			}

			@include breakpoint(max) {
				width: 17px;
				height: 30px;
			}
		}
	}
	&__prev {
		left: 10px;

		&:hover svg,
		&:focus svg {
			transform: translateX(-3px);
		}
		&:active svg {
			transform: translateX(-5px);
		}
	}
	&__next {
		right: 10px;

		&:hover svg,
		&:focus svg {
			transform: translateX(3px);
		}
		&:active svg {
			transform: translateX(5px);
		}
	}
}
