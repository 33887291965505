//*******************************************************************************
// BUTTONS
//*******************************************************************************

//
// Button Primary
//
.btn {
	@include font-family(regular);
	position: relative;
	display: inline-block;
	min-height: 50px;
	padding: 12px 24px;
	line-height: 20px;
	color: color(bright);
	background-color: color(aqua);
	border: 3px solid color(aqua);
	border-radius: 30px;
	outline: none;
	text-decoration: none;
	cursor: pointer;
	transition: $transition;

	&.btn--hover,
	&.btn--pressed,
	&:focus,
	&:hover,
	&:active {
		outline: none;
		text-decoration: none;
		color: color(white);
		background-color: color(magenta);
		border-color: color(magenta);
	}

	.js-focus-visible &:active:not(.focus-visible),
	&.btn--pressed,
	&:active {
		background-color: #AB0672;
		border-color: #AB0672;
	}

	.js-focus-visible &.focus-visible {
		background-color: #AB0672;
		border-color: color(black);
		box-shadow: none;
	}

	@include breakpoint(mid) {
		// padding: 8px 2vw 10px 2vw;
		height: 60px;
		padding: 15px 36px 12px 36px;
		font-size: 18px;
		line-height: 26px;
	}
}



//
// Button Secondary
//
// .btn--secondary {
// 	color: color(bright);
// 	background-color: color(middlegrey);
// 	border-color: color(middlegrey);

// 	&.btn--hover,
// 	&.btn--pressed,
// 	&:focus,
// 	&:hover,
// 	&:active {
// 		background-color: color(magenta);
// 		border-color: color(magenta);
// 	}
// }



//
// Button Negative Primary
//
// .btn--negative {
// 	color: color(aqua);
// 	background-color: transparent;
// 	border-color: color(aqua);
// 	transition: box-shadow $transition-time $transition-function;

// 	&.btn--hover,
// 	&.btn--pressed,
// 	&:focus,
// 	&:hover,
// 	&:active {
// 		color: color(magenta);
// 		border-color: color(magenta);
// 		background-color: transparent;
// 		box-shadow: 0 3px 7px 0 rgba(19,24,33,0.05), 0 0 5px 0 rgba(19,24,33,0.05), 0 1px 2px 0 rgba(19,24,33,0.15), 0 3px 8px 0 rgba(0,0,0,0.05), 0 0 2px 0 rgba(19,24,33,0.05);
// 	}

// 	&.btn--pressed,
// 	&:active {
// 		box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.10), inset 0 2px 6px 0 rgba(0,0,0,0.15);
// 	}
// }



//
// Link-Button (with arrow)
//
.linkbutton {
	position: relative;
	color: color(black);
	border-bottom: none;

	&::after {
		// outline: 1px solid #ccc;
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 28px;
		height: 16px;
		margin-left: 12px;
		background-size: 100%;
		background-position: left top;
		background-repeat: no-repeat;
		background-color: transparent;
		@include background-svg('<svg width="28" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M20 1a1 1 0 000 1.42l4.6 4.6H1.06a1 1 0 000 2h23.52L20 13.59A1 1 0 0020 15a1 1 0 001.41 0l6.36-6.36a.88.88 0 000-1.27L21.42 1A1 1 0 0020 1z" fill="#007E91" fill-rule="nonzero"/></svg>');
		transition: all $transition-time $transition-function;
	}

	&.btn--hover,
	&.btn--pressed,
	&:focus,
	&:hover,
	&:active {
		text-decoration: none;
		color: color(black);

		&::after {
			@include background-svg('<svg width="28" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M20 1a1 1 0 000 1.42l4.6 4.6H1.06a1 1 0 000 2h23.52L20 13.59A1 1 0 0020 15a1 1 0 001.41 0l6.36-6.36a.88.88 0 000-1.27L21.42 1A1 1 0 0020 1z" fill="#007E91" fill-rule="nonzero"/></svg>');
		}
	}

	// &.btn--hover,
	// &:focus,
	// &:hover {

	// 	&::after {
	// 		background-position: left 12px top 2px;
	// 	}
	// }
}

//
// linkbox - link with svg icon, title and smapp descriptive text
//

.linkbox {
	padding: 14px 12px;
	background: transparent;
	font-size: 16px;
	display: flex;
	margin-top: 1.25vw;
	margin-left: -12px;
	margin-right: -12px;
	line-height: 1.4;
	border-radius: $border-radius;
	@include font-family(bold);
	color: color(black);
	border: none;

	&.is-active,
	&:hover,
	&:focus {
		color: color(black);
		background: rgba(color(middlegrey), 0.2)
	}

	&+& {
		margin-top: 10px;
	}

	&__icon {
		flex: 0 0 40px;
		width: 40px;
		height: 40px;
		margin-right: 20px;
		display: block;
		@include breakpoint(md) {
			flex: 0 0 60px;
			width: 60px;
			height: 60px;
		}
	}

	&__description {
		display: block;
		@include font-family(regular);
		color: color(darkgrey);
		margin-top: 4px;
		font-size: 13px;
		line-height: 1.38;
	}
}
