//*******************************************************************************
// HEADER
//*******************************************************************************

.header {
	position: relative;
	z-index: 100;
	margin-bottom: 20vw;
	@include breakpoint(mid) {
		margin-bottom: 11vw;
	}

	&--subpage {
		margin-bottom: 0;
	}

	// topbar above main header area
	&__topbar {
		display: none;
		padding: 0 5vw;
		background: color(white);
		@include breakpoint($breakpoint-mobile-navigation) {
			display: block;
			position: relative;
			z-index: 2;
		}
	}

	&__topbar-list {
		margin: 0;
		display: flex;
		justify-content: flex-end;
		@include breakpoint($breakpoint-mobile-navigation) {
			margin-right: -15px;
		}
	}

	&__topbar-item {
		margin: 0;
		padding: 0;
		list-style: none;

		&--globe {
			display: flex;
			align-items: center;
			margin: 0 5px 0 10px;
		}
	}

	&__topbar-link {
		display: block;
		padding: 1vw 15px;
		font-size: 13px;
		font-weight: 400;
		color: color(darkgrey);
		border: none;
		&.is-active {
			color: color(magenta);
		}

		&--lang {
			color: color(middlegrey);
			padding-left: 4px;
			padding-right: 4px;
			text-transform: uppercase;

			&.is-active {
				color: color(darkgrey);
			}
		}
	}

	//header main with logo and menu
	&__main {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 5vw;
		position: relative;
		z-index: 1;
	}

	&__logo-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include breakpoint(sm) {
			flex-direction: row;
		}
	}

	&__logo {
		display: flex;
		align-items: center;
		margin-right: 1rem;
		z-index: 200;
		width: 15vw;
		min-width: 150px;
		max-width: 240px;
		border: none;

		img {
			display: block;
			width: 100%;
			height: auto;
			min-height: auto;
		}
	}
}

.header__pagetitle {
	@include font-family(bold);
	color: color(darkgray);
	z-index: 200;
}
