//*******************************************************************************
// Blog
//*******************************************************************************


.blog {

	&__filter {

	}

	&__main {
		position: relative;
	}

	&__date-nav {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: 2;
		@include breakpoint(lg) {
			display: block;
		}
	}

	&__posts {
	}

	&__post {

	}

	&__linkbutton {
		text-align: center;
	}

}
