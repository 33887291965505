/*!
 * die wegmeister gmbh
 * https://www.die-wegmeister.com/
 *
 */


@charset 'UTF-8';

// 1. Configuration and helpers
@import
	'abstracts/variables',
	'abstracts/mixins',
	'abstracts/functions',
	'abstracts/pesticide',
	'abstracts/debugging';


// 2. Vendors
@import
	'vendor/bootstrap-reboot',
	'vendor/tiny-slider';


// 3. Base stuff
@import
	'base/fonts',
	'base/base',
	'base/spacer',
	'base/typography',
	'base/helpers',
	'base/color-helpers';


// 4. Layout-related sections
@import
	'layout/header',
	'layout/menu',
	'layout/main',
	'layout/section',
	'layout/footer';


// 5. Components
@import
	'components/molecules/media-infos',
	'components/molecules/pagetitle',
	'components/molecules/title',
	'components/molecules/buttons',
	// 'components/molecules/flag',
	'components/molecules/form',
	'components/molecules/icons',
	'components/molecules/iframe',
	'components/molecules/image',
	'components/molecules/lists',
	'components/molecules/tooltip',
	'components/molecules/video',
	'components/molecules/train',
	'components/molecules/interactive-map',
	'components/molecules/map',
	'components/molecules/partner',
	'components/molecules/filter',
	'components/molecules/title-visual',
	'components/molecules/alert',
	'components/molecules/date-nav',
	'components/molecules/stickylivecam',

	'components/accordion',
	'components/blogpost',
	'components/blogpost--columns',
	'components/blogpost--imagegrid',
	'components/blogpost--media',
	'components/blogpost--milestone',
	'components/downloadlist',
	'components/newsletterform',
	'components/relatedtiles',
	'components/carousel',
	'components/teaser',
	'components/textmedia',
	'components/cookieplaceholder',
	'components/animations',
	'components/intro',
	'components/team',
	'components/timeline',
	'components/list-grid',
	'components/slideshow',
	'components/image-blend',
	'components/map-section',
	'components/webcam',
	'components/blog',
	'components/tabcontainer'
;



// 6. Page-specific styles
// @import
// 	'pages/boxedlayout';


// 7. Themes
// @import
//	'themes/color-theme';
