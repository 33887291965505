// copy from module repository https://bitbucket.org/diewegmeister/db.neos.tabcontainer/src/master/
// with minimal color adjustments
.tabcontainer {
	text-align: center;

	@include breakpoint(mid) {
		text-align: center;
		padding-left: 5vw;
		padding-right: 5vw;
	}

	.list {
		margin: 0;
	}

	&__navlist {
		display: flex;
		margin-left: -.5vw;
		margin-right: -.5vw;
		margin-bottom: 0;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;

		@include breakpoint(mid) {
			flex-wrap: nowrap;
		}

		li {
			display: inline-flex;
			height: 79px;
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: 30%;
			flex-direction: column;
			justify-content: flex-end;

			@include breakpoint(mid) {
				flex-grow: 1;
				flex-shrink: 1;
				flex-basis: initial;
				max-width: 15vw;

				&:not(:last-child) {
					margin-right: 0;
				}

				&:first-child {
					.tabcontainer__navlink-wrapper {
						border-top-left-radius: $border-radius;
					}
				}

				&:last-child {
					.tabcontainer__navlink-wrapper {
						border-top-right-radius: $border-radius;
					}
				}
			}

			@include breakpoint(max) {
				height: 4.6vw;
			}

			&.is-active {
				.tabcontainer__navlink-wrapper {
					.tabcontainer__navlink {
						color: color(magenta);
						border-bottom-color: color(magenta);
					}
				}
			}
		}
	}

	&__navlink-wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@include breakpoint(mid) {
			border-radius: 0;
		}
	}

	&__navlink {
		display: grid;
		height: 72px;
		padding-left: 1vw;
		padding-right: 1vw;
		@include bodytextsize();
		line-height: 1.2 !important;

		@include breakpoint(mid) {
		}

		@include breakpoint(max) {
			height: 4.2vw;
		}

		&:hover,
		&:focus {
			color: color(magenta);
		}

		span {
			place-self: center;
		}
	}
}
